<template>
  <div class="routeContainer">
    <FormComponent :data="data" />
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent";

export default {
  name: "Contact",
  components: {
    FormComponent
  },
  data() {
    return {
      data: {
        title: "Heeft uw een vraag? ",
        text: `Wij kunnen uw vragen beantwoorden maandag t/m zaterdag van 9:00 tot 20:00`,
        contactBtns: true,
        form: true
      }
    };
  }
};
</script>
