<template>
  <div class="ExploreComponent" v-ripple>
    <div style="width: 100%;" class="d-flex align-center justify-space-between ">

        <div v-if="loading" class="d-flex justify-content-center" style="margin:0 auto;">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div v-if="image">
            <img v-if="image.split('http').length > 1" :src="image" :alt="vakName">
            <img v-else :src="importImg('andere')" :alt="vakName"/>
          </div>
        </div>

        <div class="ExploreComponentTitle">
          <h3>{{ vakName }}</h3>
          <i class="fa-light fa-angle-right"></i>
        </div>

    </div>
  </div>
</template>

<script>
import "@/assets/styles/components.css";

export default {
  name: "Explore",
  props: ["vak"],
  data() {
    const vakName = this.vak
    let imageName = null;
    if (vakName === 'andere') {
      imageName = 'andere';
    } else {
      this.loading = true
      this.$Auth.getVakImage(vakName).then(imageUrl => {
        this.image = imageUrl;
      }).catch(err => {
        // console.log(err)
      }).finally(() => {
        this.loading = false
      })
    }
    return {
      loading: false,
      vakName,
      image: imageName,
    };
  },
  methods: {
    encodeImgName(imgName) {
      const newImgName = imgName
          .replaceAll("/", "_")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll(" ", "_")
          .toLowerCase();

      return newImgName;
    },
    importImg(imgName) {
      return require(`@/assets/img/items/${this.encodeImgName(imgName)}.jpg`);
    }
  }
};
</script>

<style scoped></style>
