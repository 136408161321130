<template>
  <div v-if="visable">
    <v-breadcrumbs divider="/" :items="breadcrumbsItems"></v-breadcrumbs>
    <div class="routeContainer adresContainer">
      <h3>Toegevoegde adressen</h3>
      <div v-if="address" class="adres">
        <div class="left">
          <i class="fa-light fa-location-dot locationIcon"></i>
          <div class="ml-4">
            <!-- <p>
              {{ adres.city }}
              -
              {{ adres.postalCode }}
            </p>
            <p>
              {{ adres.streetName }}

              {{ adres.streetNumber }}
            </p> -->
            <p>{{ address }}</p>
          </div>
        </div>
      </div>
      <div class="mb-5" v-else>
        <p class="mb-5">Er zijn nog geen adres toegevoegd</p>
      </div>
      <div class="adresForm">
        <GmapAutocomplete class="GmapAutocomplete" @place_changed="setAdres" />
        <v-btn
          @click="updateAdres"
          class="confimBtn"
          color="primary"
          icon
          outlined
        >
          <i class="fa-light fa-location-plus" style="margin: 0 auto"></i>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import haversine from "haversine-distance";

export default {
  name: "Adres",
  data() {
    this.$Auth.getAdres().then((address) => {
      this.address = address.formatted;
      this.visable = true;
    });
    return {
      selectedAdres: null,
      address: null,
      visable: false,
      breadcrumbsItems: [
        {
          text: "Instellingen",
          href: "#/settings",
        },
        {
          text: "Profiel",
          href: "#/settings/profile",
        },
        {
          text: "Toegevoegde adressen",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    setAdres(place) {
      this.selectedAdres = place;
    },
    async updateAdres() {
      if (!this.selectedAdres) return;
      const selectedAddressGeometryLocation =
        this.selectedAdres.geometry.location;
      const lat = selectedAddressGeometryLocation.lat();
      const lng = selectedAddressGeometryLocation.lng();
      const stomerij = { latitude: 51.568353, longitude: 4.638513 };
      const userGeometry = { latitude: lat, longitude: lng };
      const kmDistance =
        Math.round(haversine(userGeometry, stomerij) / 10) / 100;

      if (kmDistance > 25) {
        this.$noty.error("We kunnen helaas deze adres niet bereiken");
        return;
      }
      const parsedAddress = await this.parrseGooglePlaces(this.selectedAdres);
      if (!parsedAddress.streetName || !parsedAddress.streetNumber) {
        this.$noty.error("Graag straat en straatnummer opgeven!");
        return;
      }

      const newAdres = {
        formatted: this.selectedAdres.formatted_address,
        geometry: userGeometry,
      };
      this.$Auth.setAdres(newAdres).then((status) => {
        if (!status) {
          this.$noty.error("Er is iets mis gegaan!");
          return;
        }
        this.$noty.success("Uw adres is toegevoegd!");
        this.address = newAdres.formatted;
      });
    },
    async parrseGooglePlaces(results) {
      const map = {
        street_number: "streetNumber",
        route: "streetName",
        sublocality_level_1: "city",
        locality: "city1",
        administrative_area_level_1: "state",
        postal_code: "postalCode",
        country: "country",
      };
      const parsedAddress = {};
      results.address_components.forEach((component) => {
        parsedAddress[map[component.types[0]]] = component.long_name;
      });
      return parsedAddress;
    },
  },
};
</script>
