<template>
  <div>
    <div class="FormComponent">
      <div class="text">
        <h3>{{ title }}</h3>
        <p>{{ text }}</p>
      </div>
      <div v-if="buttons">
        <v-btn
          v-for="(button, index) in buttons"
          :key="index"
          color="primary"
          large
          outlined
          @click="button.callbackFn"
        >
          <i :class="button.icon"></i>
          {{ button.text }}
        </v-btn>
      </div>

      <v-form v-if="type == 'login'" ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="form.login.email"
          :rules="rules.email"
          label="Uw email"
          placeholder="voorbeeld@gmail.com"
          required
          outlined
        ></v-text-field>

        <v-text-field
          v-model="form.login.password"
          :rules="rules.required"
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword1 ? 'text' : 'password'"
          @click:append="showPassword1 = !showPassword1"
          label="Uw wachtwoord"
          placeholder=""
          required
          outlined
        ></v-text-field>

        <div class="buttonWrapper">
          <div v-ripple @click="submit" class="button">
            <span> Inloggen </span>
          </div>
        </div>
      </v-form>

      <v-form
        v-if="type == 'register'"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="form.register.name"
          :rules="rules.required"
          label="Uw naam"
          placeholder="voor-en achternaam"
          maxlength="25"
          counter
          outlined
          required
        ></v-text-field>

        <v-text-field
          v-model="form.register.email"
          :rules="rules.email"
          label="Uw email"
          placeholder="voorbeeld@gmail.com"
          required
          outlined
        ></v-text-field>

        <v-text-field
          v-model="form.register.password"
          :rules="[...rules.required, rules.min]"
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword1 ? 'text' : 'password'"
          @click:append="showPassword1 = !showPassword1"
          label="Uw wachtwoord"
          hint="Ten minste 8 tekens"
          required
          outlined
        ></v-text-field>
        <v-text-field
          v-model="form.register.againPassword"
          :rules="[...rules.required, rules.min, matchingPasswords]"
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword2 ? 'text' : 'password'"
          @click:append="showPassword2 = !showPassword2"
          label="Opnieuw uw wachtwoord"
          hint="Ten minste 8 tekens"
          required
          outlined
        ></v-text-field>
        <div class="buttonWrapper">
          <div v-ripple @click="submit" class="button">
            <span> Registreren </span>
          </div>
        </div>
      </v-form>
    </div>
    <div v-if="footer" class="FormComponentFooter">
      <p>
        {{ footer.text + " " }}
        <router-link :to="footer.link.path">{{ footer.link.text }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthFormComponent",
  props: ["data"],
  data() {
    return {
      type: this.data.type,
      showPassword1: false,
      showPassword2: false,
      title: this.data.title,
      text: this.data.text,
      callbackfn: this.data.callbackfn || false,
      valid: true,
      buttons: this.data.contactBtns,
      footer: this.data.footer,
      form: {
        register: {
          name: "",
          email: "",
          password: "",
          againPassword: "",
        },
        login: {
          email: "",
          password: "",
        },
      },
      rules: {
        required: [(value) => !!value || "Dit veld is verplicht."],
        min: (v) => v.length >= 8 || "Min 8 characters",
        email: [
          (v) => !!v || "E-mail is verplicht veld",
          (v) => /.+@.+\..+/.test(v) || "E-mail moet geldig zijn",
        ],
      },
    };
  },
  methods: {
    matchingPasswords() {
      if (this.form.register.password === this.form.register.againPassword) {
        return true;
      } else {
        return "Wachtwoorden komen niet overeen.";
      }
    },
    progress() {
      return Math.min(100, this.value.length * 10);
    },
    color() {
      return ["error", "warning", "success"][Math.floor(this.progress / 40)];
    },
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      const status = this.$refs.form.validate();

      if (this.type == "register") {
        if (status) this.callbackfn(this.form.register);
      } else if (this.type == "login") {
        if (status) this.callbackfn(this.form.login);
      }

      // if (status) {
      //   this.$parent.$parent.loading = true;
      //   setTimeout(() => {
      //     this.$parent.$parent.loading = false;
      //     this.$swal.fire({
      //       title: "Successfol gestuurd",
      //       text: "Uw bericht is successfol gestuurd!",
      //       confirmButtonText: "Yes",
      //       timer: 5000,
      //       timerProgressBar: true
      //     });
      //     this.$refs.form.reset();
      //   }, 2000);
      // }
    },
  },
};
</script>
