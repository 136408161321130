<template>
  <div class="FormComponent">
    <div class="text">
      <h3>{{ title }}</h3>
      <p>{{ text }}</p>
    </div>

    <div v-if="contactBtns">
      <v-btn color="primary" large outlined @click="mail">
        <i class="fa-light fa-envelope"></i>
        Mailen</v-btn
      >
      <v-btn class="ml-4" color="primary" large outlined @click="call">
        <i class="fa-light fa-phone"></i> Bellen</v-btn
      >
    </div>
<!--    <v-form v-if="form" ref="form" v-model="valid" lazy-validation>-->
<!--      <v-text-field-->
<!--        v-model="form.name"-->
<!--        :rules="rules.name"-->
<!--        label="Uw naam"-->
<!--        placeholder="voor-en achternaam"-->
<!--        required-->
<!--        outlined-->
<!--      ></v-text-field>-->

<!--      <v-text-field-->
<!--        v-model="form.email"-->
<!--        :rules="rules.email"-->
<!--        label="Uw email"-->
<!--        placeholder="voorbeeld@gmail.com"-->
<!--        required-->
<!--        outlined-->
<!--      ></v-text-field>-->

<!--      <v-textarea-->
<!--        v-model="form.message"-->
<!--        :rules="rules.message"-->
<!--        label="Uw bericht"-->
<!--        placeholder=" . . ."-->
<!--        maxlength="500"-->
<!--        counter-->
<!--        outlined-->
<!--        required-->
<!--      >-->
<!--      </v-textarea>-->

<!--      <div class="buttonWrapper">-->
<!--        <div v-ripple @click="submit" class="button">-->
<!--          <span>-->
<!--            versturen-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </v-form>-->
  </div>
</template>

<script>
export default {
  name: "FormComponent",
  props: ["data"],
  data() {
    return {
      title: this.data.title,
      text: this.data.text,
      form: this.data.form,
      contactBtns: this.data.contactBtns,
      callbackfn: this.data.callbackfn || false,
      valid: true,
      form: {
        name: "",
        email: "",
        message: ""
      },
      rules: {
        name: [v => !!v || "Uw naam is verplicht"],
        email: [
          v => !!v || "E-mail is verplicht",
          v => /.+@.+\..+/.test(v) || "E-mail moet geldig zijn"
        ],
        message: [v => !!v || "Bericht is verplicht"]
      }
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      const status = this.$refs.form.validate();

      if (status) {
        this.$parent.$parent.loading = true;
        setTimeout(() => {
          this.$parent.$parent.loading = false;
          this.$swal.fire({
            title: "Successfol gestuurd",
            text: "Uw bericht is successfol gestuurd!",
            confirmButtonText: "Yes",
            timer: 5000,
            timerProgressBar: true
          });
          this.$refs.form.reset();
        }, 2000);
      }
    },
    mail() {
      window.open("mailto:stomerij.ettenleur@gmail.com");
    },
    call() {
      window.open("tel:0765013020");
    }
  }
};
</script>
