<template>
  <div v-if="visable" class="routeContainer SubscriptionsDetail">
    <div class="header mb-6">
      <h3 class="mb-2">{{ memberschip.name }}</h3>
      <p>{{ memberschip.startDate }} - {{ memberschip.endDate }}</p>

      <Badge
        v-if="memberschip.status === 'active'"
        color="green"
        :text="memberschip.status"
      />
      <Badge v-else :text="memberschip.status" />
    </div>
    <img
      style="width: 85%"
      class="elevation-4 ml-auto mr-auto d-flex justify-center rounded-xl"
      :src="qrcodeImg"
      :alt="id"
    />
    <p class="body-2 text-center mt-6 mb-2">
      {{ id }}
    </p>
  </div>
</template>

<script>
import Badge from "@/components/Badge.vue";
import QRCode from "qrcode";

export default {
  name: "SubscriptionsDetail",
  components: {
    Badge,
  },
  data() {
    const id = this.$route.params.id;
    this.$Auth.getMemberschip(id).then((foundedMemberschip) => {
      QRCode.toDataURL(id)
        .then((url) => {
          this.qrcodeImg = url;
          if (!foundedMemberschip) {
            throw Error("Invalid id");
          }

          const startSeconds = foundedMemberschip.start.seconds
          const endSeconds = foundedMemberschip.end.seconds
          const currentSeconds = (new Date()).getTime() / 1000

          if (!foundedMemberschip.activated) {
            foundedMemberschip.currentStatus = 'nog niet actief'
          } else if (startSeconds <= currentSeconds && endSeconds >= currentSeconds) {
            foundedMemberschip.currentStatus = 'actief'
          } else if (endSeconds < currentSeconds) {
            foundedMemberschip.currentStatus = 'verlopen'
          }
          this.memberschip = foundedMemberschip;
          this.visable = true;
        })
        .catch((err) => {
          this.$noty.error("Er is een fout opgetreden");
          this.$router.push("/settings/subscriptions");
        });
    });
    return {
      id,
      qrcodeImg: null,
      memberschip: null,
      visable: false,
    };
  },
};
</script>
