<template>
  <div v-if="granded">
    <router-view/>
  </div>
</template>

<script>
import {getAuth} from "firebase/auth";

export default {
  name: "granded",
  data() {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      this.$router.push("/settings/profile");
    }
    return {
      granded: currentUser ? false : true,
      firebaseCodeChecks: this.$parent.firebaseCodeChecks,
      auth: this.$Auth,
    };
  },
  methods: {
    async signed(user, credential) {
      const userData = user;
      await this.$Auth.getUserOrMakeItIfNotExist(userData);
      this.$noty.success("Uw bent ingelogd");
      // this.$router.push("/settings/profile");
    },
  },
};
</script>
