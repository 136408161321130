<template>
  <div v-if="visable">
    <v-breadcrumbs divider="/" :items="breadcrumbsItems"></v-breadcrumbs>

    <div class="orderDetailContainer routeContainer">
      <div class="header d-flex justify-space-between align-baseline">
        <div>
          <h3>Bestelling</h3>
          <p>{{ element.date }}</p>
          <div class="badges">
            <Badge
                v-for="(badge, index) in badges"
                :key="index"
                :text="badge.text"
                :color="badge.color"
            />
          </div>
        </div>
        <div @click="dialog.visible = true" class="rounded-circle pa-3 d-flex" v-ripple style="margin: auto 0;">
          <i class="fa-light fa-qrcode fa-2x"></i>
        </div>
      </div>
      <div v-if="status" class="mt-2 mb-2 primary py-2 px-4">
        <p class="white--text">
          {{ status }}
        </p>
      </div>
      <div class="orders">
        <NestedExploreComponent
            class="order"
            v-for="(order, index) in element.orders"
            :key="index"
            :dataObj="order"
            type="simple"
        />
      </div>
      <div class="footer">
        <p class="bold">Totaal:</p>
        <p class="bold">
          {{ "€ " + totalPrice() }}
        </p>
      </div>
    </div>
    <v-dialog
        v-model="dialog.visible"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title calss="d-flex">
          <span class="text-h5">Bestelling qrcode</span>
        </v-card-title>
        <div v-if="dialog.loading" class="d-flex justify-center mt-5 mb-5">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <img
              style="width: 85%"
              class="elevation-4 ml-auto mr-auto d-flex justify-center rounded-xl"
              :src="dialog.image"
              :alt="dialog.code"
          />
          <p class="body-2 text-center mt-6 mb-2">
            {{ dialog.code }}
          </p>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog.visible = false"
          >Annuleren
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NestedExploreComponent from "@/components/NestedExploreComponent";
import Badge from "@/components/Badge";
import QRCode from "qrcode";

export default {
  name: "OrderDetail",
  components: {
    NestedExploreComponent,
    Badge,
  },
  data() {
    const groupId = this.$route.params.groupId;
    this.$Auth.getGroupedOrders().then(async (groupedOrders) => {
      const founded = groupedOrders.find((el) => el.groupId === groupId);
      if (!founded) {
        await this.$router.push("/settings/profile/orders");
        this.$noty.error("Uw bestelling is niet gevonden");
        return;
      }

      this.element = Object.assign({}, founded);
      this.element.date = this.$Auth.createFulldate(new Date(founded.date._seconds * 1000));
      this.element.orders = this.element.orders.filter(order => order.id !== "ZENDING_KOSTEN")
      this.element.orders.push(...founded.orders.filter(order => order.id === "ZENDING_KOSTEN"))
      const transactionStatus = await this.$Auth.getTransactionStatus(this.element.transactionId);
      this.badges.push({
        text: transactionStatus.stateName === 'CANCEL' ? 'betaling geannuleerd' : transactionStatus.stateName === 'PAID' ? 'betaald' : transactionStatus.stateName === 'PENDING' ? 'betaling in afwachting' : transactionStatus.stateName.toLowerCase(),
        color: transactionStatus.stateName === 'CANCEL' ? 'red' : transactionStatus.stateName === 'PAID' ? 'green' : 'grey',
      })
      this.shipLess = this.element.orders.every(order => order.shipLess || (order.id === "ZENDING_KOSTEN" && order.price === 0))
      this.status =
          this.visable = true;
      this.generateBandges();
      QRCode.toDataURL(this.element.groupId)
          .then((url) => {
            this.dialog.image = url;
            this.dialog.code = this.element.groupId
            this.dialog.loading = false;
          })
      if (founded.timeBlockStatus === "specific") {
        const date = this.$Auth.createFulldate(new Date(founded.timeBlock.start.seconds * 1000));
        const startTime = new Date(founded.timeBlock.start.seconds * 1000).getHours() + ':' + new Date(founded.timeBlock.start.seconds * 1000).getMinutes();
        const endTime = new Date(founded.timeBlock.end.seconds * 1000).getHours() + ':' + new Date(founded.timeBlock.end.seconds * 1000).getMinutes();
        this.status = `${date}, ${startTime} - ${endTime}`
      } else {
        this.status = this.shipLess ? false : 'Zo snel mogenlijk.'
      }
    });

    return {
      shipLess: null,
      dialog: {
        visible: false,
        code: '',
        image: null,
        loading: true,
      },
      status: null,
      group: groupId,
      element: [],
      visable: false,
      badges: [],
      breadcrumbsItems: [
        {
          text: "Instellingen",
          href: "#/settings",
        },
        {
          text: "Profiel",
          href: "#/settings/profile",
        },
        {
          text: "Historische bestellingen",
          href: "#/settings/profile/orders",
        },
        {
          text: "Bestelling",
          disabled: true,
        },
      ],
    };
  },
  methods: {

    formatPrice(number) {
      return new Intl.NumberFormat("nl-NL").format(number);
    },
    totalPrice() {
      const totalPrice = this.element.totalPrice
      return this.formatPrice(totalPrice);
    },
    generateBandges() {
      if(this.shipLess) return;
      if (!this.element.status) {
        this.badges.push(
            {
              text: "Onbekend",
              color: "red",
            },
        );
      }
      if (this.element.status === "open") {
        this.badges.push(
            {
              text: "Besteld",
              color: "orange",
            },
        );
      } else if (this.element.status === "closed") {
        this.badges.push(
            {
              text: "Afgeleverd",
              color: "green",
            },
        );
      } else if (this.element.status === "interrupted") {
        this.badges.push(
            {
              text: "Onderbroken",
              color: "red",
            },
        );
      }
    },
  },
};
</script>
