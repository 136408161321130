<template>
  <div class="routeContainer">
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top:15vh;justify-content: center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-breadcrumbs divider="/" :items="breadcrumbsItems"></v-breadcrumbs>
      <div v-if="subscriptions.length" class="d-flex flex-column" style="gap:40px">
        <div v-for="(subscription, i) in subscriptions" :key="i" class="d-flex flex-column" >
          <SubscriptionsComponent :subscription="subscription"/>
        </div>
      </div>
      <div v-else>
        <p>Geen abonnementen van toepassing</p>
      </div>
    </div>
  </div>
</template>
<script>
import SubscriptionsComponent from "@/components/SubscriptionsComponent";

export default {
  name: "Subscriptions",
  components: {
    SubscriptionsComponent,
  },
  data() {
    const user = this.$Auth.getCurrentUser()
    if (user)
      this.$Auth.getMemberschipsFormatted().then((res) => {
        this.subscriptions = res;
      }).finally(() => {
        this.loading = false;
      });

    return {
      loading: true,
      subscriptions: [],
      breadcrumbsItems: [
        {
          text: "Instellingen",
          href: "#/settings",
        },

        {
          text: "Abonnementen",
          disabled: true,
        },
      ],
    };
  },
};
</script>
