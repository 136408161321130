<template>
  <div>
    <v-breadcrumbs divider="/" :items="breadcrumbsItems"> </v-breadcrumbs>
    <ProductDetailView :data="data" />
  </div>
</template>
<script>
import ProductDetailView from "@/components/ProductDetailView";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "Korting5",
  components: {
    ProductDetailView,
    Breadcrumb,
  },
  data() {
    const DBItemsOther = this.$parent.DBItemsOther;
    return {
      data: {
        ...DBItemsOther.find((el) => el.name === "5% korting"),
        img: require("@/assets/img/items/abonnement_big.jpg"),
      },
      breadcrumbsItems: [
        {
          text: "Abonnement",
          href: "#/abonnement",
        },

        {
          text: "5% korting abonnement",
          disabled: true,
        },
      ],
    };
  },
};
</script>
