<template>
  <div class="routeContainer ">
    <v-breadcrumbs divider="/" :items="breadcrumbsItems"> </v-breadcrumbs>

    <RadioComponent :header="header" :selected="1" :options="Languages" />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import RadioComponent from "@/components/RadioComponent";

export default {
  name: "Language",
  components: {
    Breadcrumb,
    RadioComponent
  },
  data() {
    return {
      Languages: [
        {
          name: "Nederlands",
          value: 1
        },
        {
          name: "English",
          disabled: true,
          value: 2
        }
      ],
      header: {
        title: "Taal keuzes",
        icon: "fa-language"
      },
      breadcrumbsItems: [
        {
          text: "Instellingen",
          href: "#/settings"
        },
        {
          text: "Taal",
          disabled: true
        }
      ]
    };
  }
};
</script>
