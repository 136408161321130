<template>
  <div class="routeContainer">
    <v-breadcrumbs divider="/" :items="breadcrumbsItems"></v-breadcrumbs>

    <div v-for="item in items" :key="item.name">
      <NestedExploreComponent
          :dataObj="item"
          :itemRoute="route"
      />
    </div>
  </div>
</template>

<script>
import NestedExploreComponent from "@/components/NestedExploreComponent";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "NestedExplore",
  components: {
    NestedExploreComponent,
    Breadcrumb
  },
  data() {
    const path = this.$route.path.toLowerCase();
    const route =
        path.split("reparatie").length > 1
            ? "reparatie"
            : path.split("stomerij").length > 1
                ? "stomerij"
                : path.split("strijkservice").length > 1
                    ? "strijkservice"
                  : path.split("abonnementen").length > 1 ? "abonnementen"
                      : undefined;

    const section = path.replace(`/${route}/`, "").replace('_', ' ');
    const DBItems = route == "stomerij" ? this.$parent.DBItemsStomerij
                  : route == "reparatie" ? this.$parent.DBItemsReparatie
                  : route == "strijkservice" ? this.$parent.DBItemsStrijkService
                  : route == "abonnementen" ? this.$parent.DBItemsOther
                  : [];
    const renderItems = DBItems.filter(el => el.section === (section !== 'andere' ? section : ''));
    // if (!renderItems.length) this.$router.push("/not-found");
    return {
      breadcrumbsItems: [
        {
          text: route.charAt(0).toUpperCase() + route.slice(1),
          href: `#/${route}`
        },
        {
          text: this.capitalizeWords(section.replace("_", " ")),
          disabled: true
        }
      ],
      items: renderItems,
      route
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    capitalizeWords(string) {
      return string.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
      });
    }
  }
};
</script>

<style scoped></style>
