<template>
  <div v-if="granded">
    <router-view />
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";

export default {
  name: "granded",
  data() {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      this.$router.push("/settings");
    }
    return {
      granded: currentUser ? true : false,
      firebaseCodeChecks: this.$parent.firebaseCodeChecks,
    };
  },
  methods: {
    signOut() {
      const auth = getAuth();
      auth.signOut();
      this.$router.push("/settings");
      this.$noty.warning("U bent uitgelogd");
    },
  },
};
</script>
