<template>

  <div class="SubscriptionsComponent">
    <p v-if="activated" class="label">{{ startDate }} - {{ endDate }}</p>
    <div @click="activatePopup" class="white" style="height: 100px; text-align: start;">
      <div v-if="loading" class="d-flex justify-content-center" style="justify-content: center">
        <v-progress-circular
            style="margin-top:10%; "
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="SubscriptionsComponentBlock " v-ripple>
        <div v-if="img">
          <img :src="img" :alt="`${name} foto`"/>
        </div>
        <div class="right">
          <div class="header">
            <h3>{{ name }}</h3>
            <i v-if="statusClass == 'active'" class="fa-light fa-qrcode"></i>
          </div>
          <div class="status">
            <i
                :class="statusClass"
                v-if="statusClass == 'active'"
                class="fa-light fa-check"
            ></i>
            <i
                :class="statusClass"
                v-if="statusClass == 'inactive'"
                class="fa-light fa-circle-exclamation"
            ></i>
            <p :class="statusClass">{{ status }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SubscriptionsComponent",
  props: ["subscription"],
  data() {

    const status = ["niet meer geldig", "actief", "inactief"];
    const statusClasses = ["expired", "active", "inactive"];

    this.$Auth.getImage(this.subscription.subscriptionId).then(image => {
      if (image) {
        this.img = image;
      }
    }).catch(err => {
      // console.log(err)
    }).finally(() => {
      this.loading = false
    })
    return {
      name: this.subscription.name,
      status: this.subscription.status,
      statusClass: statusClasses[status.indexOf(this.subscription.status)],
      activated: this.subscription.activated,
      startDate: this.subscription.startDate,
      endDate: this.subscription.endDate,
      validForDays: this.subscription.validForDays,
      img: null,
      loading: true,
      id: this.subscription.id,
    };
  },
  methods: {
    importImg(imgName) {
      return require(`@/assets/img/items/${imgName}.jpg`);
    },
    updateView(newMemberschip) {
      const status = ["niet meer geldig", "actief", "inactief"];
      const statusClasses = ["expired", "active", "inactive"];
      this.status = newMemberschip.status;
      this.statusClass = statusClasses[status.indexOf(this.status)];
      this.activated = newMemberschip.activated;
      this.startDate = newMemberschip.startDate;
      this.endDate = newMemberschip.endDate;
      this.validForDays = newMemberschip.validForDays;
      this.id = newMemberschip.id;
    },
    async activateActivation() {
      this.loading = true
      const activatedMemberShip = await this.$Auth.getMemberschipsFormatted()
      this.loading = false
      if (activatedMemberShip.find(membership => membership.status === 'actief')) {
        this.$swal.fire({
          title: "U hebt al een actieve abonnement",
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
        return
      }
      this.$swal
          .fire({
            title: "Wilt u uw abonnement activeren?",
            text: `Wilt u uw abonnement activeren? Hij is dan vanaf nu geldig voor ${this.validForDays} dagen. Deze actie kan niet ongedaan worden`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Activeren",
            cancelButtonText: "Annuleren",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
          })
          .then((res) => {
            if (res.isConfirmed) {
              this.$Auth.activateMembership(this.id).then(async () => {
                const activatedMemberShip = await this.$Auth.getMemberschip(this.id)

                if (activatedMemberShip.activated) {
                  this.updateView(activatedMemberShip);
                  this.$swal.fire({
                    title: "Uw abonnement is geactiveert!",
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                  });
                } else {
                  this.$swal.fire({
                    title: "Er is iets mis gegaan",
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                  });
                }
              });
            }
          });
    },
    activatePopup() {
      if (this.activated) {
        this.$router.push(`/settings/subscriptions/${this.id}`);
        return;
      }
      this.$Auth.couldBeActivated().then((couldBeActivated) => {
        if (couldBeActivated) {
          this.activateActivation();
        } else {
          this.$swal.fire({
            title: "Er is al een abonnoment actief!",
            text: `Uw kan geen twee abonnoment in het zelfde tijd activeren`,
            icon: "error",
          });
        }
      });
    },
  },
};
</script>
