<template>
  <div class="SettingsComponent">
    <div class="top">
      <i class="fa-light" :class="icon"></i>
      <h3>
        {{ title }}
      </h3>
    </div>
    <div class="bottom">
      <div class="buttonWrapper" v-if="button">
        <v-btn :to="button.path" color="primary">
          {{ button.text }}
        </v-btn>
      </div>
      <div class="elements" v-else-if="elements">
        <div class="element" v-for="element in elements" :key="element.text">
          <router-link :to="element.path">
            {{ element.text }}
            <i class="fa-light fa-angle-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SettingsComponent",
  props: ["data"],
  data() {
    return {
      title: this.data.title,
      icon: this.data.icon,
      button: this.data.button || false,
      elements: this.data.elements || false,
    };
  },
};
</script>
<style scoped>
.buttonWrapper {
  justify-content: flex-start;
}
.buttonWrapper {
  margin-bottom: 10px;
}
</style>
