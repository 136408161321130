<template>
  <div>
    <div v-if="small">
      <div class="mt-5 mb-1 d-flex justify-space-between">
        <p>
          {{ name }}
        </p>
        <p style="margin-right: 5px">
          {{ formatPrice(price) + ' ' }}
        </p>
      </div>
    </div>

    <div v-else>
      <div
          class="NestedExploreComponent"
          :class="!loading &&
      (!image ? 'ShortNestedExploreComponent' : '') +
      (simple ? 'simpleNestedExploreComponent' : '')
    "
          v-if="!(removeWhenZero && !(count > 0))"
      >
        <div v-if="loading" class="d-flex justify-content-center mr-5">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <img class="rounded" v-if="image" :src="image" :alt="name + ' foto'"/>
        </div>
        <div class="NestedExploreComponentTitle">
          <h3 style="margin-left: 25px">{{ name }}</h3>
          <div class="price" :class="simple ? 'simplePrice' : ''">
            <div
                @click="handleRemoveItem"
                v-if="count > 0 && !simple"
                class="icon iconLeft"
                v-ripple
            >
              <i v-if="count > 1" class="fa-light fa-minus"></i>
              <i v-else class="fa-regular fa-trash-can"></i>
            </div>
            <p class="count" v-if="count">{{ count }}x</p>
            <p v-if="priceFixerPlace == 'left'">{{ priceFixer }}</p>
            <p>€{{ (price + "").replace(".", ",") }}</p>
            <p v-if="priceFixerPlace == 'right'">{{ priceFixer }}</p>
            <p v-if="simple && count > 1">
              ({{ "€ " + formatPrice(price * count) + " " }})
            </p>

            <div
                v-if="!max && !simple"
                @click="handleAddItem"
                class="icon"
                v-ripple
            >
              <i class="fa-light fa-plus"></i>
            </div>
            <div
                v-else-if="max > count && !simple"
                @click="handleAddItem"
                class="icon"
                v-ripple
            >
              <i class="fa-light fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/components.css";

export default {
  name: "ExtendedExplore",
  props: ["dataObj", "removeWhenZero", "itemRoute", "type"],
  data() {
    const DBItem = {...this.dataObj};

    if (!(this.removeWhenZero && !((DBItem?.count || 0) > 0)) && DBItem.id !== "ZENDING_KOSTEN") {
      this.$Auth.getImage(DBItem.id).then(image => {
        this.image = image
      }).catch((err) => {
      }).finally(() => {
        this.loading = false;
      })
    }

    return {
      small: DBItem.id === "ZENDING_KOSTEN",
      loading: true,
      image: null,
      name: DBItem.name,
      price: typeof DBItem.price == 'number' ? DBItem.price : DBItem.priceNumber,
      priceFixer: DBItem.priceFixer || "",
      priceFixerPlace: DBItem.priceFixer == "p/m²" ? "right" : "left",
      count: DBItem?.count || 0,
      route: this.itemRoute,
      simple: this.type == "simple",
      itemType: this.type || "normal",
      max: DBItem.max ? DBItem.max : false,
      shipLess: DBItem.shipLess ? DBItem.shipLess : false,
      DBItem,
    };
  },
  mounted() {
    document.addEventListener("clearShoppingCard", this.refreshItem);
  },
  methods: {
    formatPrice(number) {
      if (number === 0) return 'NVT'
      return '€ ' + new Intl.NumberFormat("nl-NL").format(number);
    },
    refreshItem() {
      this.DBItem = {...this.dataObj};
      this.name = this.DBItem.name;
      this.price = this.DBItem.price;
      this.priceFixtures = this.DBItem.priceFixtures || "";
      this.count = this.DBItem.count || 0;
    },
    encodeImgName(imgName) {
      const newImgName = imgName
          .replaceAll("/", "_")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll(" ", "_")
          .replaceAll("%", "")
          .toLowerCase();
      return newImgName;
    },
    importImg() {
      return require(`@/assets/img/items/${this.encodeImgName(this.name)}.jpg`);
    },
    handleAddItem() {
      this.count += 1;

      this.$parent.$parent.addToshoppingCard({
        ...this.dataObj,
        count: this.count,
        route: this.route,
      });
      document.dispatchEvent(this.$parent.$parent.shoppingCard);
    },
    handleRemoveItem() {
      this.count -= 1;
      this.$parent.$parent.removeFromshoppingCard({
        ...this.dataObj,
        count: this.count,
        route: this.route,
      });
      document.dispatchEvent(this.$parent.$parent.shoppingCard);
    },
  },
};
</script>

<style scoped></style>
