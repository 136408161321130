<template>
  <div class="CheckoutComponent">
    <div v-if="card.length">
      <div class="top">
        <div v-for="item in card" :key="item.name">
          <div v-if="item.count > 0" class="row">
            <p class="name">
              {{ item.name }}{{ item.count > 1 ? ` (${item.count})` : "" }}
            </p>
            <p class="price">
              {{ item.fixtures }}
              {{ formatPrice(item.price * item.count) }}
            </p>
          </div>
        </div>
        <div v-if="calcShipping() != 'not applicable'" class="row">
          <p class="name">Verzending</p>
          <p class="price" :class="calcShipping() == 0 ? 'free' : ''">
            {{ calcShipping() == 0 ? "gratis" : formatPrice(calcShipping()) }}
          </p>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="name">Totaal</div>
        <div class="price">
          {{
            formatPrice(
                totalPrice +
                (calcShipping() == "not applicable" ? 0 : calcShipping())
            )
          }}
        </div>
      </div>
      <div class="btn" @click="getTimeBlocks" v-ripple>
        <p>Doorgaan naar afrekenen</p>
      </div>
    </div>
    <div v-else>
      <p>Uw heeft niks in uw wasmand</p>
    </div>

    <v-dialog
        v-model="dialog.visible"
        persistent
        max-width="600px"
    >
      <v-card v-if='new Date() < new Date("2023-08-22")'>
        <v-alert
            type="warning"
        >
          Alle bestellingen die geplaatst worden na 28 juli, zullen pas na 21 augustus worden opgehaald
        </v-alert>
      </v-card>
      <v-card>
        <v-card-title calss="d-flex">
          <span class="text-h5">Beschikbaar tijd kiezen</span>
        </v-card-title>

        <div v-if="dialog.loading" class="d-flex justify-center mt-5 mb-5">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div
              style="width: 80%; margin: 0 auto; border: 2px solid #1064b0;"
              class="mt-4 px-3 py-2 mb-2 roundeds d-flex justify-space-between"
              :style="dialog.selected === 'as fast as possible' ? 'background-color: #1e88e5; color: white' : ''"
              @click="dialog.selected = 'as fast as possible'"
              v-ripple
          >
            <p class="mb-0">
              Zo snel mogenlijk
            </p>
          </div>
          <div v-for="(timeBlock,index) in timeBlocks"
               :key="index"
               style="width: 80%; margin: 0 auto; border: 1px solid #1e88e5; cursor: pointer"
               class="mt-4 px-3 py-2 mb-2 roundeds d-flex justify-space-between"
               :style="dialog.selected.id === timeBlock.id && dialog.selected.start.seconds === timeBlock.start.seconds && timeBlock.status !== 'finished' ? 'background-color: #1e88e5; color: white' : timeBlock.status == 'finished' ? ' text-decoration: line-through;' : '' "
               @click="timeBlock.status !== 'finished' ? dialog.selected = timeBlock : null"
               v-ripple
          >
            <p class="mb-0">
              {{ getDay(timeBlock.start) }}
              {{ new Date(timeBlock.start.seconds * 1000).toISOString().slice(0, 10).split('-').reverse().join('-') }}
            </p>
            <p class="mb-0 text-muted">
              {{ formatTime(timeBlock.start) }} - {{ formatTime(timeBlock.end) }}
            </p>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
          >Annuleren
          </v-btn>
          <v-btn
              color="blue darken-1"
              outlined
              @click="handleCheckout"
              :loading="dialog.loadingBtn"
          >Betalen ({{
              formatPrice(
                  totalPrice +
                  (calcShipping() == "not applicable" ? 0 : calcShipping())
              )
            }})
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "@/assets/styles/components.css";

export default {
  name: "CheckoutComponent",
  props: [],
  data() {
    const totalPrice = this.getTotalPrice();
    const DBItemsStomerij = this.$parent.$parent.DBItemsStomerij;
    const DBItemsReparatie = this.$parent.$parent.DBItemsReparatie;
    const DBItemsStrijkService = this.$parent.$parent.DBItemsStrijkService;
    const DBItemsOther = this.$parent.$parent.DBItemsOther;
    let card = [];
    if (DBItemsStomerij.filter((el) => el.count > 0).length) {
      card.push(...DBItemsStomerij.filter((el) => el.count > 0));
    }
    if (DBItemsReparatie.filter((el) => el.count > 0).length) {
      card.push(...DBItemsReparatie.filter((el) => el.count > 0));
    }
    if (DBItemsStrijkService.filter((el) => el.count > 0).length) {
      card.push(...DBItemsStrijkService.filter((el) => el.count > 0));
    }
    if (DBItemsOther.filter((el) => el.count > 0).length) {
      card.push(...DBItemsOther.filter((el) => el.count > 0));
    }

    return {
      timeBlocks: [],
      dialog: {
        loadingBtn: false,
        selected: 'as fast as possible',
        loading: true,
        visible: false,
      },
      card,
      totalPrice,
      DBItemsStomerij,
      DBItemsReparatie,
      DBItemsStrijkService,
      DBItemsOther,
      dayArray: [
        'Zo',
        'Ma',
        'Di',
        'Wo',
        'Do',
        'Vr',
        'Za',
      ]
    };
  },
  mounted() {
    document.addEventListener("shoppingCard", this.handleTrigger);
  },
  methods: {
    closeDialog() {
      this.dialog = {
        loadingBtn: false,
        selected: 'as fast as possible',
        loading: true,
        visible: false,
      };
    },
    getWeekDayDateTime(timeBlock, date) {
      const day = new Date(timeBlock.start.seconds * 1000).getDay();
      const fromTime = new Date(timeBlock.start.seconds * 1000).toString().slice(16, 21);
      const toTime = new Date(timeBlock.end.seconds * 1000).toString().slice(16, 21);

      const first = date.getDate() - date.getDay() + 1
      const currentWeekDate = new Date(date.setDate(first + (day - 1))).toISOString().slice(0, 10)
      const currentWeekDateTimeStart = new Date(`${currentWeekDate} ${fromTime}:00`);
      const currentWeekDateTimeEnd = new Date(`${currentWeekDate} ${toTime}:00`);
      return {
        start: {
          seconds: currentWeekDateTimeStart.getTime() / 1000
        },
        end: {
          seconds: currentWeekDateTimeEnd.getTime() / 1000
        }
      }
    },
    filterTimeBlocks(timeBlocks) {
      const weeklyTimeBlocks = [];
      const filteredTimeBlocks = timeBlocks.filter((timeBlock, index) => {
        if (timeBlock.status === 'removed') return
        if (timeBlock.type === 'weekly') {
          const date = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 7))
          const thisDateTime = this.getWeekDayDateTime(timeBlock, new Date())
          const secondWeekDateTime = this.getWeekDayDateTime(timeBlock, date)
          if (thisDateTime.start.seconds * 1000 > new Date().getTime())
            weeklyTimeBlocks.push({
              ...timeBlock,
              ...thisDateTime
            })
          if (secondWeekDateTime.start.seconds * 1000 > new Date().getTime())
            weeklyTimeBlocks.push({
              ...timeBlock,
              ...secondWeekDateTime
            })
          return false
        }
        const startDate = timeBlock.start.seconds * 1000
        if (new Date().getTime() > startDate) return

        return timeBlock
      })
      filteredTimeBlocks.push(...weeklyTimeBlocks)
      if (!filteredTimeBlocks.length) return []
      return filteredTimeBlocks.slice(0, 20).sort((a, b) => a.start.seconds - b.start.seconds);
    },
    formatTime(timeBlockDate) {
      const date = new Date(timeBlockDate.seconds * 1000)
      const timeOtions = {
        hour: 'numeric',
        minute: 'numeric',
      }
      return date.toLocaleTimeString('nl-NL', timeOtions)
    },
    getDay(timeBlockDate) {
      const dateIndex = new Date(timeBlockDate.seconds * 1000).getDay()
      return this.dayArray[dateIndex]
    },
    getTimeBlocks() {
      if (this.card.find(el => !el?.shipLess)) {
        this.dialog.visible = true
        this.$Auth.getTimeBlocks().then(timeBlocks => {
          this.timeBlocks = this.filterTimeBlocks(timeBlocks);
        }).catch(err => {
          this.dialog.visible = false;
          this.$swal.fire({
            title: "Er is iets fouts gegaan",
            icon: "error",
          });
        }).finally(() => {
          this.dialog.loading = false;
        })
      } else {
        this.handleCheckout()
      }
    },

    calcShipping() {
      const foundedForShipping = this.card.filter((el) => !el.shipLess);
      if (foundedForShipping.length) {
        const totalPrice = Math.round(
            foundedForShipping.reduce((ac, el) => ac + el.price * el.count, 0)
        );

        if (totalPrice > 50) return 0;

        return 12.5;
      }
      return "not applicable";
    },
    cardWatcher() {
      this.card = [];
      if (this.DBItemsStomerij.filter((el) => el.count > 0).length) {
        this.card.push(...this.DBItemsStomerij.filter((el) => el.count > 0));
      }
      if (this.DBItemsReparatie.filter((el) => el.count > 0).length) {
        this.card.push(...this.DBItemsReparatie.filter((el) => el.count > 0));
      }
      if (this.DBItemsStrijkService.filter((el) => el.count > 0).length) {
        this.card.push(...this.DBItemsStrijkService.filter((el) => el.count > 0));
      }
      if (this.DBItemsOther.filter((el) => el.count > 0).length) {
        this.card.push(...this.DBItemsOther.filter((el) => el.count > 0));
      }
    },
    async handleCheckout() {
      this.dialog.loadingBtn = true
      const timeBlock = this.dialog.selected
      if (!this.$parent.$parent.online) {
        this.$swal.fire({
          title: "Niet verbonden met internet!",
          text: "Helaas kunt u geen bestelling plaatsen zonder internet!",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
        return;
      }
      // this.$parent.$parent.loading = true;
      const user = this.$Auth.getCurrentUser();
      if (!user) {
        this.$swal
            .fire({
              title: "Niet ingelogd!",
              text: "U moet ingelogd zijn om te bestellen!",
              icon: "error",
              confirmButtonText: "Inloggen",
              confirmButtonColor: "#3085d6",
            })
            .then((res) => {
              if (res.isConfirmed) {
                this.$router.push("/login");
              }
            });
        return;
      }
      const adres = await this.$Auth.getAdres();
      if (!adres.formatted.length || !adres.geometry) {
        this.$swal
            .fire({
              title: "Geen adres!",
              text: "U moet een adres opgeven in uw profiel",
              icon: "error",
              confirmButtonText: "Adres opgeven",
              confirmButtonColor: "#3085d6",
            })
            .then((res) => {
              if (res.isConfirmed) {
                this.$router.push("/settings/profile/adres");
              }
            });
        return;
      }
      try {
        let orders = [...this.card];
        const {paymentURL} = await this.$Auth.pushOrders(orders, timeBlock);
        await window.location.replace(paymentURL);
      } catch (err) {
        this.$noty.error("Er is enige fout opgetreden");
        return;
      }

      this.dialog.loadingBtn = false;
      this.dialog.visible = false;
      document.dispatchEvent(this.$parent.$parent.clearShoppingCard);
      this.updatePrice();
      this.card = [];
      this.$parent.$parent.cleanShoppingCard();
      this.$noty.success("Uw bestelling is succesvol geregistreerd");
    },
    getTotalPrice() {
      const stomerijTotal = this.$parent.$parent.DBItemsStomerij.reduce(
          (acc, el) => acc + el.price * (el.count ? el.count : 0),
          0
      );
      const reparatieTotal = this.$parent.$parent.DBItemsReparatie.reduce(
          (acc, el) => acc + el.price * (el.count ? el.count : 0),
          0
      );
      const strijkServiceTotal = this.$parent.$parent.DBItemsStrijkService.reduce(
          (acc, el) => acc + el.price * (el.count ? el.count : 0),
          0
      );
      const otherTotal = this.$parent.$parent.DBItemsOther.reduce(
          (acc, el) => acc + el.price * (el.count ? el.count : 0),
          0
      );
      return (
          Math.round((stomerijTotal + reparatieTotal + strijkServiceTotal + otherTotal) * 100) / 100
      );
    },
    formatPrice(price) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
    updatePrice() {
      this.totalPrice = this.getTotalPrice();
    },
    handleTrigger() {
      this.cardWatcher();
      this.updatePrice();
    },
    importImg(imgName) {
      return require(`@/assets/img/items/${this.$parent.$parent.encodeImgName(
          imgName
      )}.jpg`);
    },
  },
};
</script>

<style scoped></style>
