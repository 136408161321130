<template>
  <v-app style="background: transparent">
    <div id="app">
      <Header />
      <router-view />
      <Footer />
    </div>
  </v-app>
</template>

<script>
import "vuejs-noty/dist/vuejs-noty.css";
import "./assets/styles/app.css";
import Header from "@/views/base/Header.vue";
import Footer from "@/views/base/Footer.vue";

export default {
  name: "app",
  metaInfo: {
    title: "Stomerij Roselaar",
    titleTemplate: "Home | %s",
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style>
#app {
  box-sizing: content-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 15px;
  width: 94%;
}
/* .v-application {
  background: transparent;

} */

/* .theme--light.v-application {
  background: transparent;
} */
.v-application p {
  margin-bottom: 0 !important;
}
</style>
