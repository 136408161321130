import firebaseConfig from "./firebaseConfig.js";
import {initializeApp} from "firebase/app";
import {collection, doc, getDoc, getDocs, getFirestore, setDoc,} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {nanoid} from "nanoid";
import {getMessaging, getToken} from "firebase/messaging";
import {getDownloadURL, getStorage, ref, uploadBytes, listAll} from 'firebase/storage'

class Auth {
    #firebaseConfig;
    #app;
    #db;
    #auth;
    #messaging;
    #storage;

    constructor() {
        this.#firebaseConfig = firebaseConfig;
        this.#app = initializeApp(this.#firebaseConfig);
        this.#db = getFirestore(this.#app);
        this.#auth = getAuth();
        this.#messaging = getMessaging(this.#app);
        this.#storage = getStorage()
        this.init();
        this.months = [
            'Jan',
            'Feb',
            'Mrt',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dec',
        ]
        this.days = ["ma", "di", "woe", "do", "vr", "za", "zo"]
    }

    getStorage() {
        return this.#storage
    }

    async getStorageRef(child) {
        const storageRef = await ref(this.getStorage(), child)
        return storageRef
    }

    async uploadToStore(storageRef, file) {
        const snapshot = await uploadBytes(storageRef, file)
        return snapshot
    }

    async getImgUrl(fileName) {
        const reference = await this.getStorageRef(fileName)
        try {
            return await getDownloadURL(reference)
                .then(url => {
                    return Promise.resolve(url);
                })
                .catch(error => {
                    if (error.code === 'storage/object-not-found') {
                        return Promise.resolve(false);
                    } else {
                    }
                })
        } catch (e) {
            console.log(e)
            return undefined
        }
    }

    getFirebaseMessaging() {
        return this.#messaging;
    }


    getCollectionRef = collName => {
        const collRef = collection(this.#db, collName)
        return collRef
    }

    getDocs = async collName => {
        const collectionRef = this.getCollectionRef(collName)
        const docs = await getDocs(collectionRef)
        return docs
    }

    init() {
        // this.requestNotificationPermission();
    }

    requestNotificationPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                console.log("Notification permission granted.");
                this.notification();
            }
        });
    }

    notification() {
        getToken(this.#messaging, {
            vapiKey: "4t0RCtdl1iIflro3mlJKhzSnwnRD6ViR9qhzi8uKrFQ",
        })
            .then((currentToken) => {
                if (currentToken) {
                    // console.log(currentToken);
                } else {
                    console.log(
                        "No registration token available. Request permission to generate one."
                    );
                }
            })
            .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
            });
    }

    getApp() {
        return this.#app;
    }

    getDb() {
        return this.#db;
    }

    getAuth() {
        return this.#auth;
    }

    generateId = (length) => {
        return nanoid(length);
    };

    #getCollectionRef = (collName) => {
        const collRef = collection(this.#db, collName);
        return collRef;
    };
    #getDocumentRef = (collName, docName) => {
        const docRef = doc(this.#db, collName, docName);
        return docRef;
    };

    getDoc = async (collName, docName) => {
        const documentRef = this.#getDocumentRef(collName, docName);
        const doc = await getDoc(documentRef);
        return doc;
    };

    setDoc = async (collName, docName, data = {}) => {
        const docRef = this.#getDocumentRef(collName, docName);
        await setDoc(docRef, data);
    };

    getData = async (collName, docName, fieldName = undefined) => {
        const doc = await this.getDoc(collName, docName);
        let data = doc;
        if (fieldName) {
            data = await doc.get(fieldName);
        } else {
            data = data.data();
        }
        return data;
    };

    getCurrentUser = () => {
        const currentUser = this.#auth.currentUser;
        return currentUser;
    };

    getCurrentUserUid = () => {
        const currentUser = this.getCurrentUser();
        const currentUserUid = currentUser.uid;
        return currentUserUid;
    };

    createFulldate = dateField => {
        let d = ''
        if (dateField.seconds) {
            d = new Date(dateField.seconds * 1000)
        } else {
            d = new Date(dateField)
        }
        const year = d.getFullYear()
        const date = d.getDate()
        const day = this.days[d.getDay()];
        const month = this.months[d.getMonth()]
        const fullDate = `${date} ${month} ${year}`
        return fullDate
    }
}

export default Auth;
