<template>
  <div class="mt-10">
    <p class="bold text-h5">404</p>
    <h2 class="text-h4 text">
      Niet gevonden
    </h2>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
