<template>
  <div class="routeContainer">
    <div v-for="data in elements" :key="data.title">
      <SettingsComponent :data="data" />
    </div>
  </div>
</template>
<script>
import SettingsComponent from "@/components/SettingsComponent";
import { getAuth } from "firebase/auth";

export default {
  name: "Settings",
  components: {
    SettingsComponent,
  },
  data() {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    const profileElements = [
      currentUser
        ? {
            text: "Mijn profiel",
            path: "/settings/profile",
          }
        : {
            text: "Login",
            path: "/login",
          },
    ];

    return {
      user: null,
      elements: [
        {
          title: "Profiel",
          icon: "fa-user",
          elements: profileElements,
        },
        {
          title: "Talen",
          icon: "fa-language",
          elements: [
            {
              text: "Taal keuzes",
              path: "/settings/language",
            },
          ],
        },
        {
          title: "Abonnement",
          icon: "fa-wallet",
          elements: [
            {
              text: "Mijn abonnementen",
              path: "/settings/subscriptions",
            },
          ],
        },
        // {
        //   title: "Voorwaarden",
        //   icon: "fa-memo",
        //   elements: [
        //     {
        //       text: "Algemene voorwaarden",
        //       path: "/settings/algemene_voorwaarden"
        //     },
        //     {
        //       text: "Overige informatie",
        //       path: "/settings/overige_informatie"
        //     },
        //     {
        //       text: "Diensten",
        //       path: "/settings/diensten"
        //     }
        //   ]
        // }
      ],
    };
  },
};
</script>
