<template>
  <div v-if="visable" class="ProductDetailView">
    <img :src="data.img" :alt="`${data.name} picture`" />
    <h3>
      {{ data.name }}
    </h3>
    <h4>
      {{ formatPrice(data.price) }}
    </h4>
    <p>
      {{ data.description }}
    </p>
    <div class="buttonWrapper">
      <div v-if="alreadyAdded">
        <div @click="handleRemoveItem" class="button">
          <i class="fa-light fa-trash-alt"></i>
        </div>
        product al toegevoegd
      </div>
      <div v-else @click="clickAddItem" class="button">
        <i class="fa-light fa-plus"></i>
        <span> Toevoegen </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDetailView",
  props: ["data"],
  data() {
    this.$Auth.alreadyHaveMemberShip().then((result) => {
      this.alreadyHaveMemberShip = result;
      this.visable = true;
    });
    return {
      ...this.data,
      alreadyAdded: this.data.count,
      alreadyHaveMemberShip: null,
      visable: false,
    };
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
    handleRemoveItem() {
      this.alreadyAdded = false;
      this.$parent.$parent.removeFromshoppingCard({
        ...this.data,
        route: "abonnementen",
      });
      document.dispatchEvent(this.$parent.$parent.shoppingCard);
    },
    handleAddItem() {
      this.alreadyAdded = true;
      this.$parent.$parent.addToshoppingCard({
        ...this.data,
        route: "abonnementen",
      });
      document.dispatchEvent(this.$parent.$parent.shoppingCard);
    },
    clickAddItem() {
      if (this.alreadyHaveMemberShip) {
        this.$swal
          .fire({
            title: "Weet u het zeker?",
            text: "Uw heeft al een abonnoment. zie instellingen > Abonnoment.",
            showCancelButton: true,
            confirmButtonText: "In winkelmand",
            cancelButtonText: "Annuleer",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
          })
          .then((res) => {
            if (res.isConfirmed) {
              this.handleAddItem();
            }
          });
      } else {
        this.handleAddItem();
      }
    },
  },
};
</script>
