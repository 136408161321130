<template>
  <div class="routeContainer">
    <div v-for="item in DBItemsStomerij" :key="item.name">
      <NestedExploreComponent
        :dataObj="item"
        :removeWhenZero="true"
        itemRoute="stomerij"
      />
    </div>
    <div v-for="item in DBItemsReparatie" :key="item.name">
      <NestedExploreComponent
        :dataObj="item"
        :removeWhenZero="true"
        itemRoute="reparatie"
      />
    </div>
    <div v-for="item in DBItemsStrijkService" :key="item.name">
      <NestedExploreComponent
          :dataObj="item"
          :removeWhenZero="true"
          itemRoute="strijkservice"
      />
    </div>
    <div v-for="item in DBItemsOther" :key="item.name">
      <NestedExploreComponent
        :dataObj="item"
        :removeWhenZero="true"
        itemRoute="abonnementen"
      />
    </div>
    <CheckoutComponent />
  </div>
</template>

<script>
import NestedExploreComponent from "@/components/NestedExploreComponent";
import CheckoutComponent from "@/components/CheckoutComponent";

export default {
  name: "Checkout",
  components: {
    CheckoutComponent,
    NestedExploreComponent,
  },
  data() {
    return {
      DBItemsStomerij: this.$parent.DBItemsStomerij,
      DBItemsReparatie: this.$parent.DBItemsReparatie,
      DBItemsStrijkService: this.$parent.DBItemsStrijkService,
      DBItemsOther: this.$parent.DBItemsOther,
    };
  },
};
</script>

<style scoped></style>
