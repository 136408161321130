<template>
  <div v-if="visable" class="BreadcrumbComponent">
    <div v-for="(BreadcrumbValue, i) in BreadcrumbC" :key="BreadcrumbValue">
      <h3>
        <div v-if="i != BreadcrumbC.length - 1">
          <router-link
            :to="
              `/${Breadcrumb.slice(0, i + 1)
                .join('/')
                .toLowerCase()}`
            "
          >
            {{ BreadcrumbValue }}
          </router-link>
          &nbsp; > &nbsp;
        </div>
        <div v-else>
          {{ BreadcrumbValue }}
        </div>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["Breadcrumb"],
  data() {
    return {
      visable: this.Breadcrumb.length > 0,
      BreadcrumbC: this.Breadcrumb
    };
  }
};
</script>

<style scoped>
.v-application a {
  color: #2c3e50 !important;
}
</style>
