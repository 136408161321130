import Vue from "vue";
import Router from "vue-router";

import Main from "@/Main";
import GrandedForLogged from "@/views/GrandedForLogged";
import GrandedForNotLogged from "@/views/GrandedForNotLogged";

import Products from "@/views/Products";
import Stomerij from "@/views/Stomerij";
import Reparatie from "@/views/Reparatie";
import StrijkService from "@/views/StrijkService";
import NestedExplore from "@/views/NestedExplore";
import Checkout from "@/views/Checkout";
import Abonnement from "@/views/Abonnement";
import Korting5 from "@/views/Korting5";
import Zakelijk from "@/views/Zakelijk";
import Contact from "@/views/Contact";
import Settings from "@/views/Settings";
import Language from "@/views/Language";
import Subscriptions from "@/views/Subscriptions";
import SubscriptionsDetail from "@/views/SubscriptionsDetail";
import Login from "@/views/Login";
import Register from "@/views/Register";
import Profile from "@/views/Profile";
import Adres from "@/views/Adres";
import Orders from "@/views/Orders";
import OrdersDetail from "@/views/OrdersDetail";
import Notfound from "@/views/Notfound";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: Main,
      children: [
        {
          path: "/home",
          name: "Home",
          component: () => import("../views/Home"),
        },
        {
          name: "Products",
          path: "/products",
          component: Products,
        },
        {
          name: "stomerij",
          path: "/stomerij",
          component: Stomerij,
        },
        {
          name: "Nestedstomerij",
          path: "/stomerij/*",
          component: NestedExplore,
        },
        {
          name: "reparatie",
          path: "/reparatie",
          component: Reparatie,
        },
        {
          name: "Nestedreparatie",
          path: "/reparatie/*",
          component: NestedExplore,
        },
        {
          name: "strijkService",
          path: "/strijkService",
          component: StrijkService,
        },
        {
          name: "NestedStrijkService",
          path: "/strijkService/*",
          component: NestedExplore,
        },
        {
          name: "Checkout",
          path: "/checkout",
          component: Checkout,
        },
        {
          name: "Abonnement",
          path: "/abonnementen",
          component: Abonnement,
        },
        {
          name: "Nestedabonnementen",
          path: "/abonnementen/*",
          component: NestedExplore,
        },
        {
          name: "Zakelijk",
          path: "/zakelijk",
          component: Zakelijk,
        },
        {
          name: "Settings",
          path: "/settings",
          component: Settings,
        },
        {
          name: "Language",
          path: "/settings/language",
          component: Language,
        },
        {
          name: "Subscriptions",
          path: "/settings/subscriptions",
          component: Subscriptions,
        },
        {
          name: "SubscriptionsDetail",
          path: "/settings/subscriptions/:id",
          component: SubscriptionsDetail,
        },
        {
          name: "Contact",
          path: "/contact",
          component: Contact,
        },
        {
          path: "/",
          component: GrandedForLogged,
          children: [
            {
              name: "Profile",
              path: "/settings/profile",
              component: Profile,
            },
            {
              name: "Adres",
              path: "/settings/profile/adres",
              component: Adres,
            },
            {
              name: "Orders",
              path: "/settings/profile/orders",
              component: Orders,
            },
            {
              name: "OrdersDetail",
              path: "/settings/profile/orders/:groupId",
              component: OrdersDetail,
            },
          ],
        },
        {
          path: "/",
          component: GrandedForNotLogged,
          children: [
            {
              name: "Login",
              path: "/login",
              component: Login,
            },
            {
              name: "Register",
              path: "/register",
              component: Register,
            },
          ],
        },
        {
          name: "Notfound",
          path: "/*",
          component: Notfound,
        },
      ],
    },
  ],
});
