<template>
  <div class="routeContainer">
    <FormComponent :data="data" />
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent";

export default {
  name: "Zakelijk",
  components: {
    FormComponent
  },
  data() {
    return {
      data: {
        title: "Wilt u met ons zakelijk werken?",
        text: `Wij bedien al voor meerdere bedrijven services aan en kunnen niet
        wachten om ze voor uw te bieden. Hieronder kunt u contact opnemen met
        ons.`,
        contactBtns: true,
        form: true
      }
    };
  }
};
</script>
