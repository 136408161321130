<template>
  <div class="RadioComponent">
    <div class="header">
      <i class="fa-light" :class="icon"></i>
      <h3>{{ title }}</h3>
    </div>
    <v-radio-group v-model="radioGroup">
      <div class="radio" v-for="n in optionsC" :key="n.value">
        <v-radio :label="n.name" :value="n.value" :disabled="n.disabled">
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: ["selected", "options", "header"],
  data() {
    return {
      title: this.header.title,
      icon: this.header.icon,
      radioGroup: this.selected,
      optionsC: this.options
    };
  }
};
</script>
