// import axios from "axios";
import Auth from "./Auth.js";
import subscriptions from "@/views/Subscriptions";
import axios from "axios";

class StomerijAuth extends Auth {
    #defaultUserData;

    constructor() {
        super();
        this.#defaultUserData = {
            uid: '',
            token: '',
            user: {
                name: '',
                email: '',
                phoneNumber: ' '
            },
            address: {
                formatted: "",
                geometry: {},
            },
            ordersRefs: [],
            memberschips: [],
        };
    }

    // Products
    #getInProducts = async (collName, fieldName = undefined) => {
        const data = await this.getData("products", collName, fieldName);
        return data;
    };

    // TODO: should removed to be dynamic
    getReiniging = async () => {
        const reiniging = await this.#getInProducts("reiniging", "data");
        return reiniging;
    };
    getReparatie = async () => {
        const reparatie = await this.#getInProducts("reparatie", "data");
        return reparatie;
    };

    getStrijkService = async () => {
        const reparatie = await this.#getInProducts("strijkservice", "data");
        return reparatie;
    };

    getOther = async () => {
        const other = await this.#getInProducts("abonnementen", "data");
        return other;
    };

    // Orders
    #getInOrders = async (collName, fieldName = undefined) => {
        const data = await this.getData("orders", collName, fieldName);
        return data;
    };

    getClosed = async () => {
        const closedOrders = await this.#getInOrders("closed", "orderGroups");
        return closedOrders;
    };
    getOpen = async () => {
        const openOrders = await this.#getInOrders("open", "orderGroups");
        return openOrders;
    };
    getInterrupted = async () => {
        const openOrders = await this.#getInOrders("interrupted", "orderGroups");
        return openOrders;
    };
    getAbonnementen = async () => {
        const abonnementenOrders = await this.#getInOrders("abonnementen", "orderGroups");
        return abonnementenOrders;
    };
    getAllOrders = async () => {
        const orders = [];
        orders.push(...(await this.getClosed()));
        orders.push(...(await this.getOpen()));
        orders.push(...(await this.getInterrupted()));
        // orders.push(...(await this.getAbonnementen()));
        return orders;
    }

    // Users
    #getInUsers = async (collName, fieldName = undefined) => {
        const data = await this.getData("users", collName, fieldName);
        return data;
    };
    getUser = async (uid) => {
        const user = await this.#getInUsers(uid);
        return user;
    };
    setUser = async (uid, data = {}) => {
        return await this.setDoc("users", uid, data);
    };

    getUserOrMakeItIfNotExist = async (user) => {
        const userStoreData = await this.getUser(user.uid);
        if (!userStoreData) {
            await axios({
                method: 'post',
                url: 'https://api.stomerijetten-leur.nl/api/v1/auth/new_user',
                data: {
                    uid: user.uid,
                    name: user.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    UserMetaData: {...user.metadata}
                }
            });
        }
    };

    getAdres = async () => {
        const user = await this.getUser(this.getCurrentUserUid());
        const address = user.address;
        if (address) {
            return address;
        }
        return null;
    };
    setAdres = async (newAddress) => {
        const user = await this.getUser(this.getCurrentUserUid());
        if (newAddress.geometry && newAddress.formatted) {
            await axios({
                method: 'patch',
                url: 'https://api.stomerijetten-leur.nl/api/v1/auth/address',
                data: {
                    token: user.token,
                    uid: user.uid,
                    latitude: newAddress.geometry.latitude,
                    longitude: newAddress.geometry.longitude,
                    formatted: newAddress.formatted
                }
            });
            return true;
        } else {
            return null;
        }
    };
    activateMemberSchipData = async (data) => {
        // const start = Math.round(new Date().getTime() / 1000);
        const start = new Date();

        let end = new Date();
        end.setDate(end.getDate() + parseInt(data.validForDays));

        const startDate = this.createFulldate(start);
        const endDate = this.createFulldate(end);

        data.status = "actief";
        data.activated = true;
        data.startDate = startDate;
        data.endDate = endDate;
        data.start = start;
        data.end = end;
    };
    couldBeActivated = async () => {
        const memberschips = await this.getMemberschips();
        const activatedMemberschips = memberschips.filter((el) => {
            if (el.activated) {
                const currentDate = new Date().getTime() / 1000;
                const currentlyUsed = el.end.seconds > currentDate;
                if (currentlyUsed) {
                    return el;
                }
            }
        });
        if (activatedMemberschips.length) return false;
        return true;
    };
    updateMembership = async (id, newMemberschip) => {
        const memberschips = await this.getMemberschips();
        const newMemberschips = memberschips.filter(memberschip => memberschip.id !== id)

        newMemberschips.push(newMemberschip)
        await this.setDoc("orders", "abonnementen", {orderGroups: newMemberschips});
    }

    activateMembership = async (id) => {
        const user = await this.getUser(this.getCurrentUserUid());
        const result = await axios({
            method: 'POST',
            url: 'https://api.stomerijetten-leur.nl/api/v1/auth/activate_subscription',
            data: {
                token: user.token,
                uid: user.uid,
                subscription: id
            }
        });
    };
    getMemberschips = async () => {
        const user = await this.getUser(this.getCurrentUserUid());
        const result = await axios({
            method: 'POST',
            url: 'https://api.stomerijetten-leur.nl/api/v1/auth/subscriptions',
            data: {
                token: user.token,
                uid: user.uid,
            }
        });
        return result.data.data;
    };
    getMemberschip = async (id) => {
        const memberschips = await this.getMemberschips();
        const memberschip = memberschips.find((el) => el.id == id);
        if (memberschip) return memberschip;
        return null;
    };
    alreadyHaveMemberShip = async () => {
        const user = await this.getUser(this.getCurrentUserUid());
        const userMemberschips = user.memberschips;
        if (userMemberschips.length == 0) return false;
        const validMemberschips = userMemberschips.filter((el) => {
            if (!el?.end) return el;
            const currentDate = new Date().getTime() / 1000;
            if (el.end.seconds > currentDate) return el;
        });
        return validMemberschips.length ? true : false;
    };
    getMemberschipsFormatted = async () => {
        const memberschips = await this.getMemberschips();
        const formattedMemberschips = [];

        memberschips.forEach((el) => {
            let status = "inactief"; // if there is a problem//issue
            if (el.activated) {
                const currentDate = Math.round(new Date().getTime() / 1000);
                if (currentDate > el.start._seconds && currentDate < el.end._seconds) {
                    status = "actief";
                } else if (currentDate < el.start._seconds) {
                    status = "Nog niet geldig";
                } else if (currentDate > el.end._seconds) {
                    status = "Niet meer geldig";
                }
            } else {
                status = "Nog niet geactiveerd";
            }
            const data = {
                validForDays: el.validForDays,
                presentage: el.presentage,
                end: el.activated ? el.end : null,
                start: el.activated ? el.start : null,
                price: el.price,
                name: el.name,
                activated: el.activated,
                startDate: el.activated ? el.startDate : null,
                endDate: el.activated ? el.endDate : null,
                subscriptionId: el.subscriptionId,
                status,
                id: el.id,
                img: el.name.replaceAll("%", "").replaceAll(" ", "_"),
            };
            formattedMemberschips.push(data);
        });

        return formattedMemberschips;
    };
    getOrders = async () => {
        const user = await this.getUser(this.getCurrentUserUid());
        return user.ordersRefs;
    };
    getGroupedOrders = async () => {
        const user = await this.getUser(this.getCurrentUserUid());
        const result = await axios({
            method: 'POST',
            url: 'https://api.stomerijetten-leur.nl/api/v1/auth/orderGroups',
            data: {
                token: user.token,
                uid: user.uid,
            }
        });
        return result.data.data
        // const res = await this.getOrders();
        // for (const el of res) {
        //     const groupId = el.groupId;

        // const fullDate = this.createFulldate(el.date);
        // const foundedGroup = groups.find((el) => el.groupId == groupId);

        // if (foundedGroup) {
        //     foundedGroup.orders.push(el);
        // } else {
        //     groups.push({
        //         secondsDate: el.date.seconds,
        //         status: el.status,
        //         groupId: groupId,
        //         date: fullDate,
        //         orders: [el],
        //     });
        // }
        // }
        groups.sort((a, b) => new Date(b.secondsDate) - new Date(a.secondsDate));

        return groups;
    };
    setOrderGroups = async (newOrderGroup, status) => {
        const orderGroups = await this.getData("orders", status, "orderGroups");
        orderGroups.push(newOrderGroup);
        await this.setDoc("orders", status, {orderGroups: orderGroups});
    };
    setOrderInTimeBlock = async (timeBlock, groupId) => {
        const foundTimeBlock = await this.getData(timeBlock.type === 'weekly' ? "timeWeekly" : 'timeOnce', timeBlock.id);
        foundTimeBlock.orderRefs.push({
            end: new Date(timeBlock.end.seconds * 1000),
            start: new Date(timeBlock.end.seconds * 1000),
            timeBlock,
            groupId
        })
        await this.setDoc(timeBlock.type === 'weekly' ? "timeWeekly" : 'timeOnce', timeBlock.id, foundTimeBlock)
    }

    addAsOpenOrderGroup = async (orders, groupId, totalPrice, timeBlock = 'as fast as possible') => {
        if (!orders.length) return;
        const orderGroup = {
            orders: orders.map((el) => this.filterOrder(el, groupId)),
            groupId,
            address: (await this.getAdres()),
            userUid: this.getCurrentUserUid() ? this.getCurrentUserUid() : 'nvt',
            date: new Date(),
            status: "open",
            totalPrice,
            timeBlockStatus: 'fast',
        };
        if (timeBlock !== 'as fast as possible') {
            orderGroup.timeBlockStatus = 'specific'
            orderGroup.timeBlock = timeBlock
            await this.setOrderInTimeBlock(timeBlock, groupId)
        }

        await this.setOrderGroups(orderGroup, "open");
    };

    async getImage(imageName) {

            const storageRef = await this.getImgUrl(imageName)
            return storageRef

    }

    async getVakImage(imageName) {
        const storageRef = await this.getImage("vak_" + imageName)
        return storageRef
    }

    pushOrders = async (orders, timeBlock) => {
        const user = await this.getUser(this.getCurrentUserUid());
        const result = await axios({
            method: 'post',
            url: 'https://api.stomerijetten-leur.nl/api/v1/payment/new_order',
            data: {
                token: user.token,
                uid: user.uid,
                products: orders,
                timeBlock
            }
        });
        return result?.data?.data
    };

    filterOrder = (order, groupId) => {
        const filteredOrder = {
            id: order.id,
            name: order.name,
            price: order.price,
            section: order.section,
            priceFixer: order.priceFixer || null,
            count: order.count || 1,
            status: "open",
            date: new Date(),
            groupId,
        };
        return filteredOrder;
    };

    pushSubscription = async (order) => {
        const userUid = await this.getCurrentUserUid();
        const user = await this.getUser(userUid);
        const subscriptionId = this.generateId(36);

        user.memberschips.push({id: subscriptionId});
        await this.setUser(userUid, user);

        const data = {
            name: order.name,
            price: order.price,
            validForDays: order.validForDays,
            presentage: order.presentage,
            activated: false,
            userUid: user.uid ? user.uid : userUid ? userUid : 'nvt',
            id: subscriptionId,
            subscriptionId: order.id
        };
        await this.setOrderGroups(data, "abonnementen");
    };


    async getTimeOnce() {
        const timeOnceRefs = await this.getDocs('timeOnce')
        const timeOnceDocs = timeOnceRefs.docs.map(doc => doc.data())
        return timeOnceDocs
    }

    async getTimeWeekly() {
        const timeWeeklyRefs = await this.getDocs('timeWeekly')
        const timeWeeklyDocs = timeWeeklyRefs.docs.map(doc => doc.data())
        return timeWeeklyDocs
    }

    async getTimeBlocks() {
        const timeOnce = await this.getTimeOnce()
        const timeWeekly = await this.getTimeWeekly()
        const timeBlocks = []
        timeBlocks.push(...timeOnce)
        timeBlocks.push(...timeWeekly)
        return timeBlocks
    }

    async getTimeBlock(timeBlockId) {
        const timeBlocks = await this.getTimeBlocks()
        return timeBlocks.filter((timeBlock) => timeBlock.id === timeBlockId)
    }

    async updateUser(userId, userUser, user) {
        await axios({
            method: 'patch',
            url: 'https://api.stomerijetten-leur.nl/api/v1/auth/user',
            data: {
                token: user.token,
                uid: user.uid,
                ...userUser
            }
        });
    }

    async getTransactionStatus(transactionId) {
        const user = await this.getUser(this.getCurrentUserUid());
        if (!user) return;
        const status = await axios({
            method: 'post',
            url: 'https://api.stomerijetten-leur.nl/api/v1/payment/transaction_status',
            data: {
                uid: user.uid,
                token: user.token,
                transactionId,
            }
        });
        return status.data.data
    }
}

export default StomerijAuth;
