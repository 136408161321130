<template>
  <router-link to="/home" class="logoContainer" v-ripple>
    <img :src="importImg('logo.png')" alt="Stomerij" />
  </router-link>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  methods: {
    importImg(imgName) {
      return require(`../../assets/img/${imgName}`);
    }
  }
};
</script>

<style></style>
