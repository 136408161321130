<template>
  <div v-if="visable" class="footerContainer" v-ripple>
    <router-link active-class="active" to="/home">
      <i class="fa-light fa-house-chimney"></i>
      <span>Home</span>
    </router-link>

    <router-link active-class="active" to="/contact">
      <i class="fa-light fa-phone"></i>
      <span>Contact</span>
    </router-link>

    <router-link active-class="active" to="/stomerij">
      <img
        class="footerContainerLogo"
        :src="importImg('small_logo.png')"
        alt="stomerij logo"
      />
    </router-link>

    <router-link active-class="active" to="/checkout">
      <i class="fa-light fa-basket-shopping">
        <span v-if="dotVisable" class="redDot none"></span>
      </i>
      <span>Wasmand</span>
    </router-link>
    <router-link active-class="active" to="/settings">
      <i class="fa-light fa-gear"></i>
      <span>Instellingen</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data() {
    return {
      visable: true,
      main: this.$parent.$children[1],
      DBItemsStomerij: null,
      DBItemsReparatie: null,
      DBItemsStrijkService: null,
      DBItemsOther: null,
      dotVisable: false,
    };
  },
  mounted() {
    document.addEventListener("shoppingCard", this.calcDotVisable);
    document.addEventListener("clearShoppingCard", () => {
      this.dotVisable = false;
    });
    document.addEventListener("hideFooter", () => (this.visable = false));
    document.addEventListener("revealFooter", () => (this.visable = true));
    const items = localStorage.getItem('items')
    if (items) {
      this.dotVisable = true
    }
  },

  methods: {
    syncData() {
      this.DBItemsStomerij = this.main.DBItemsStomerij;
      this.DBItemsReparatie = this.main.DBItemsReparatie;
      this.DBItemsStrijkService = this.main.DBItemsStrijkService;
      this.DBItemsOther = this.main.DBItemsOther;
    },
    calcDotVisable() {
      if (this.DBItemsStomerij && this.DBItemsReparatie && this.DBItemsStrijkService && this.DBItemsOther) {
        this.dotVisable = Boolean(
          this.DBItemsStomerij.filter((el) => el.count > 0).length ||
            this.DBItemsReparatie.filter((el) => el.count > 0).length ||
            this.DBItemsStrijkService.filter((el) => el.count > 0).length ||
            this.DBItemsOther.filter((el) => el.count > 0).length
        );
      } else {
        this.syncData();
        // this.calcDotVisable();
      }
    },
    importImg(imgName) {
      return require(`@/assets/img/${imgName}`);
    },
  },
};
</script>

<style scoped></style>
