<template>
  <div class="routeContainer">
    <div v-for="vak in vakken" :key="vak">
      <router-link :to="`/reparatie/${vak.toLowerCase().replace(' ', '_')}`">
        <ExploreComponent :vak="vak" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ExploreComponent from "@/components/ExploreComponent";

export default {
  name: "Reparatie",
  components: {
    ExploreComponent
  },
  data() {
    let vakken = [
      ...new Set(this.$parent.DBItemsReparatie.map(el => el.section))
    ];
    vakken = vakken.map(vak => vak === '' ? 'andere' : vak)

    return {
      vakken,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped></style>
