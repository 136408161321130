<template>
  <div>
    <div v-if="loading" class="loading">
      <self-building-square-spinner
          :animation-duration="3000"
          :size="65"
          color="white"
      />
    </div>
    <div v-if="loading2" class="loading">
      <hollow-dots-spinner :animation-duration="750" :size="65" color="white"/>
    </div>
    <router-view v-if="visable"/>
  </div>
</template>

<script>
import {SelfBuildingSquareSpinner, HollowDotsSpinner} from "epic-spinners";
import {getAuth} from "firebase/auth";

export default {
  name: "Main",
  components: {
    SelfBuildingSquareSpinner,
    HollowDotsSpinner,
  },
  data() {
    return {
      shoppingCard: new Event("shoppingCard"),
      clearShoppingCard: new Event("clearShoppingCard"),
      hideFooterEvent: new Event("hideFooter"),
      revealFooterEvent: new Event("revealFooter"),
      retrievedshoppingCard: JSON.parse(localStorage.getItem("items")) || [],
      loading: false,
      loading2: false,
      DBItemsStomerij: null,
      DBItemsReparatie: null,
      DBItemsStrijkService: null,
      DBItemsOther: null,
      visable: false,
      online: navigator.onLine,
    };
  },
  created() {
    if (this.$route.path == "/") this.$router.push("/home");

    this.fetchingDB().then(() => {
      this.handleLocalstorageData();
      document.dispatchEvent(this.shoppingCard);
      this.visable = true;
    });

    document.addEventListener("clearShoppingCard", this.cleanShoppingCard);
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },

  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    updateOnlineStatus(e) {
      const {type} = e;
      const online = type === "online";
      this.online = online;
      return online;
    },
    async fetchingDB() {
      this.DBItemsStomerij = await this.$Auth.getReiniging();
      this.DBItemsReparatie = await this.$Auth.getReparatie();
      this.DBItemsStrijkService = await this.$Auth.getStrijkService();
      this.DBItemsOther = await this.$Auth.getOther();
    },
    async checkUserExist() {
      const auth = await getAuth();
      const currentUser = await auth.currentUser;
      return currentUser ? true : false;
    },
    cleanShoppingCard() {
      this.removeCash_soft();
      this.DBItemsStomerij.map((el) => (el.count ? (el.count = 0) : el));
      this.DBItemsReparatie.map((el) => (el.count ? (el.count = 0) : el));
      this.DBItemsStrijkService.map((el) => (el.count ? (el.count = 0) : el));
      this.DBItemsOther.map((el) => (el.count ? (el.count = 0) : el));
    },
    activateLoading(whileLoading, afterLoading) {
      document.querySelector("body").style.overflow = "hidden";
      this.loading = true;
      const thisDoc = this;
      whileLoading();
      setTimeout(function () {
        thisDoc.loading = false;
        document.querySelector("body").style.overflow = "scroll";
        afterLoading();
      }, 3000);
    },
    importImg(imgName) {
      return require(`@/assets/img/${imgName}`);
    },
    addToshoppingCard(obj) {
      let findedObj = {};
      if (obj.route == "stomerij") {
        findedObj = this.DBItemsStomerij.find((el) => el.id == obj.id);
      } else if (obj.route == "reparatie") {
        findedObj = this.DBItemsReparatie.find((el) => el.id == obj.id);
      } else if (obj.route == "strijkservice") {
        findedObj = this.DBItemsStrijkService.find((el) => el.id == obj.id);
      } else if (obj.route == "abonnementen") {
        findedObj = this.DBItemsOther.find((el) => el.id == obj.id);
      }

      if (findedObj.count) {
        if (findedObj.max) {
          if (findedObj.count >= findedObj.max) {
            findedObj.count = findedObj.max;
          } else {
            findedObj.count += 1;
          }
        } else {
          findedObj.count += 1;
        }
      } else {
        findedObj.count = 1;
      }
      this._updateLocalStorage();
    },
    removeFromshoppingCard(obj) {
      let findedObj = {};
      if (obj.route === "stomerij") {
        findedObj = this.DBItemsStomerij.find((el) => el.id == obj.id);
      } else if (obj.route === "reparatie") {
        findedObj = this.DBItemsReparatie.find((el) => el.id == obj.id);
      } else if (obj.route === "strijkservice") {
        findedObj = this.DBItemsStrijkService.find((el) => el.id == obj.id);
      } else if (obj.route === "abonnementen") {
        findedObj = this.DBItemsOther.find((el) => el.id == obj.id);
      }
      findedObj.count -= 1;
      this._updateLocalStorage();
    },
    encodeImgName(imgName) {
      const newImgName = imgName
          .replaceAll("/", "_")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll(" ", "_")
          .replaceAll("%", "")
          .toLowerCase();
      return newImgName;
    },
    removeCash_soft() {
      localStorage.removeItem("items");
    },
    _updateLocalStorage() {
      let items = [];
      items.push(
          ...this.DBItemsStomerij.filter((el) => el.count > 0).map((el) => {
            return {
              type: "stomerij",
              id: el.id,
              count: el.count,
            };
          })
      );
      items.push(
          ...this.DBItemsReparatie.filter((el) => el.count > 0).map((el) => {
            return {
              type: "reparatie",
              id: el.id,
              count: el.count,
            };
          })
      );
      items.push(
          ...this.DBItemsStrijkService.filter((el) => el.count > 0).map((el) => {
            return {
              type: "strijkService",
              id: el.id,
              count: el.count,
            };
          })
      );
      items.push(
          ...this.DBItemsOther.filter((el) => el.count > 0).map((el) => {
            return {
              type: "abonnementen",
              id: el.id,
              count: el.count,
            };
          })
      );

      if (items.length) {
        localStorage.setItem("items", JSON.stringify(items));
      } else {
        localStorage.removeItem("items");
      }
    },
    retrievedMiddleware(item) {
      if (item.max) {
        if (item.count >= item.max) {
          item.count = item.max;
          this._updateLocalStorage();
        }
      }
    },
    async firebaseCodeChecks(errorCode, errorMessage) {
      // console.log(errorCode, errorMessage);
      if (errorCode == "auth/email-already-in-use") {
        this.$noty.error(`E-mail al in gebruik`);
      } else if (errorCode == "auth/user-disabled") {
        this.$noty.error(`Uw account is geblokeerd`);
      } else if (errorCode == "auth/wrong-password") {
        this.$noty.error(`Uw wachtwoord en/of email klopt niet!`);
      } else if (errorCode == "auth/user-not-found") {
        this.$noty.error("Het lijkt dat uw nog geen account heeft aangemaakt!");
      } else if (errorCode == "auth/popup-closed-by-user") {
        return;
      } else if (errorCode == "auth/requires-recent-login") {
        // TODO promptForCredentials
        this.$noty.error("Er is enige fout opgetreden");

        // const auth = getAuth();
        // const user = auth.currentUser;

        // const credential = promptForCredentials();

        // return await reauthenticateWithCredential(user, credential)
        //   .then((somthing) => {
        //     console.log(somthing);
        //     this.$noty.error(`Succesvol opnieuw geautoreerd`);
        //   })
        //   .catch((error) => {
        //     const NerrorCode = error.code;
        //     const NerrorMessage = error.message;
        //     return this.firebaseCodeChecks(NerrorCode, NerrorMessage);
        //   });
      } else {
        return "not handled";
      }
    },
    handleLocalstorageData() {
      let founded = 0;

      this.retrievedshoppingCard.forEach((el) => {
        if (el.count > 0) {
          const foundedStomerij = this.DBItemsStomerij.find((Nel) => Nel.id === el.id);
          const foundedReparatie = this.DBItemsReparatie.find((Nel) => Nel.id === el.id);
          const foundedStrijkService = this.DBItemsStrijkService.find((Nel) => Nel.id === el.id);
          const foundedOther = this.DBItemsOther.find((Nel) => Nel.id === el.id);
          if (foundedStomerij) {
            founded++;
            foundedStomerij.count = el.count;
            this.retrievedMiddleware(foundedStomerij);
          } else if (foundedReparatie) {
            founded++;
            foundedReparatie.count = el.count;
            this.retrievedMiddleware(foundedReparatie);
          } else if (foundedStrijkService) {
            founded++;
            foundedStrijkService.count = el.count;
            this.retrievedMiddleware(foundedStrijkService);
          } else if (foundedOther) {
            founded++;
            foundedOther.count = el.count;
            this.retrievedMiddleware(foundedOther);
          }
        }
      });
      if (this.retrievedshoppingCard.length > founded) {
        this.cleanShoppingCard();
        this._updateLocalStorage();
        this.$noty.error("Er is iets fout gegaan");
      }
    },
    handleFooterVisablity() {
      const vm = this;
      setTimeout(() => {
        const inputs = document.querySelectorAll("input");
        const textareas = document.querySelectorAll("textarea");
        [...inputs, ...textareas].forEach((el) => {
          el.addEventListener("focusin", () => {
            document.dispatchEvent(vm.hideFooterEvent);
          });
          el.addEventListener("focusout", () => {
            document.dispatchEvent(vm.revealFooterEvent);
          });
        });
      }, 200);
    },
  },
  updated() {
    this.handleFooterVisablity();
  },
};
</script>

<style></style>
