import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import v_Ripple from "vue-material-design-ripple";
import VueNoty from "vuejs-noty";
import Vuetify from "vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "./registerServiceWorker";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-material-design-ripple/dist/vue-material-design-ripple.css";
import "vuetify/dist/vuetify.min.css";
import "./assets/styles/app.css";
import "@mdi/font/css/materialdesignicons.css";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import xss from "xss";
import StomerijAuth from "./StomerijAuth";
import {nanoid} from "nanoid";
import * as VueGoogleMaps from "vue2-google-maps";

const Auth = new StomerijAuth();
const analytics = getAnalytics(app);
const firebaseApp = Auth.getApp();
const db = Auth.getDb();

if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', async () => {
        await navigator.serviceWorker.register('/sw.js');
    });
}

const xssFilter = (message) => {
    const outMessage = xss(
        message
            .replaceAll('"', "")
            .replaceAll("'", "")
            .replaceAll("`", "")
            .replaceAll("=", "")
            .replaceAll("{", "")
            .replaceAll("}", "")
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(";", "")
            .replaceAll("<", "")
            .replaceAll(">", "")
            .replaceAll("!", "")
            .replaceAll("-", "")
    ).trim();
    return outMessage;
};

Vue.prototype.$createId = nanoid;
Vue.prototype.$Auth = Auth;
Vue.prototype.$db = db;
Vue.prototype.$firebaseApp = firebaseApp;
Vue.prototype.$xss = xssFilter;

Vue.config.productionTip = false;
Vue.directive("ripple", v_Ripple);

const options = {
    VueNoty: {
        timeout: 4000,
        progressBar: false,
        layout: "topCenter",
    },
    Vuetify: {
        icons: {
            iconfont: "fa", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        },
        theme: {
            light: true,
            themes: {
                light: {
                    primary: "#4372b5",
                    background: "#000",
                },
            },
        },
    },
    VueSweetalert2: {
        confirmButtonColor: "#3085d6",
    },
};

Vue.use(VueNoty, options.VueNoty)
    .use(Vuetify, options.Vuetify)
    .use(VueSweetalert2, options.VueSweetalert2)
    .use(VueGoogleMaps, {
        load: {
            key: "AIzaSyAWKzmZ1tcpbkrpjiQTPegezcLjrRTOaoo",
            libraries: "places",
        },
    });

// new Vue({
//   el: "#app",
//   router,
//   vuetify: new Vuetify(options.Vuetify),
//   components: { App },
//   template: "<App/>",
// });

let app;

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
    if (!app) {
        new Vue({
            router,
            vuetify: new Vuetify(options.Vuetify),
            render: (h) => h(App),
        }).$mount("#app");
    }
});

export default new Vuetify(options.Vuetify);
