<template>
  <div class="badge" :class="badgeColor" v-ripple>
    {{ badgeText }}
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: ["color", "text"],
  data() {
    return {
      badgeColor: "badge-" + this.color,
      badgeText: this.text.charAt(0).toUpperCase() + this.text.slice(1),
    };
  },
};
</script>

<style scoped>
.badge {
  margin-top: 10px;
  background-color: #4e4e4e21;
  color: #4e4e4e;
  padding: 1px 15px;
  border-radius: 50px;
  display: inline-block;
}
.badge-orange {
  background-color: #ff660021;
  color: #ff6600;
}
.badge-red {
  background-color: #da0d0d21;
  color: #da0d0d;
}
.badge-green {
  background-color: #0caf0621;
  color: #0caf06;
}
</style>
