<template>
  <div>
    <v-breadcrumbs divider="/" :items="breadcrumbsItems"></v-breadcrumbs>
    <div class="routeContainer orderContainer">
      <div class="header">
        <h3>Historische bestellingen</h3>
        <p v-if="elements.length">{{ elements.length }} bestellingen</p>
      </div>
      <div class="elementsContainer">
        <div v-if="elements.length">
          <div
              @click="goToProduct(element)"
              class="orderElement"
              v-ripple
              v-for="(element, index) in elements"
              :key="index"
          >
            <OrderElement :element="element"/>
          </div>
        </div>
        <div v-else>Uw heeft nog geen bestellingen</div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderElement from "@/components/OrderElement";

export default {
  name: "Orders",
  components: {
    OrderElement,
  },
  data() {
    this.$Auth.getGroupedOrders().then((groupedOrders) => {
      groupedOrders.sort((a, b) => b.date._seconds - a.date._seconds);
      this.elements.push(...groupedOrders);
    });

    return {
      elements: [],
      breadcrumbsItems: [
        {
          text: "Instellingen",
          href: "#/settings",
        },
        {
          text: "Profiel",
          href: "#/settings/profile",
        },
        {
          text: "Historische bestellingen",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    goToProduct(element) {
      this.$router.push(`/settings/profile/orders/${element.groupId}`)
    }
  }
};
</script>
