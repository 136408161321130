<template>
  <div class="orderElementComponent">
    <p class="date">
      {{ date }}
    </p>
    <p class="orderText">
      {{ getOrders() }}
    </p>
  </div>
</template>
<script>
export default {
  name: "OrderElement",
  props: ["element"],
  data() {
    return {
      date: this.$Auth.createFulldate(new Date(this.element.date._seconds * 1000)),
      orders: this.element.orders,
      id: this.element.id,
    };
  },
  methods: {
    getOrders() {
      let orders = this.orders.map((el) => el.name);
      orders = orders.filter(order => order !== 'verzending')
      let joinedOrders = orders.join("");
      if (orders.length > 1) {
        joinedOrders =
          orders.slice(0, -1).join(", ") + " en " + orders.slice(-1);
      }
      return joinedOrders;
    },
  },
};
</script>
