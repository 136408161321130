<template>
  <div>
    <v-breadcrumbs divider="/" :items="breadcrumbsItems"></v-breadcrumbs>
    <div class="profileContainer routeContainer">
      <div v-if="loading" class="d-flex justify-content-center" style="justify-content: center; margin: 25vh auto">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <div class="top" @click="dialog.visible = true" v-ripple>
          <div class="text">
            <h3>
              {{ user.name }}
            </h3>
            <p>
              {{ user.email }}
            </p>
            <p
                v-if="phoneNumberExist"
            >
              {{ '+31 ' + user.phoneNumber }}
            </p>
          </div>
          <v-badge
              dot
              color="red"
              v-if="!phoneNumberExist"
          >
            <i class="fa-light fa-pen-to-square"></i>
          </v-badge>
          <i v-else class="fa-light fa-pen-to-square"></i>
        </div>

        <div class="content mt-4">
          <router-link to="/settings/profile/adres" v-ripple>
            <i class="fa-light fa-location-dot locationIcon"></i>
            <div class="ml-4" v-if="address">
              <p>
                {{ address }}
              </p>
            </div>
            <div class="ml-4" v-else>Nog geen Adres</div>
            <i class="fa-light fa-angle-right caret"></i>
          </router-link>

          <router-link
              v-if="elements.length"
              to="/settings/profile/orders"
              class="pastOrders"
              v-ripple
          >
            <div class="headerText">
              <i class="fa-light fa-truck-ramp-box"></i>
              <h3>Historische bestellingen</h3>
            </div>

            <div>
              <OrderElement
                  class="orderElement"
                  v-for="(element, index) in elements"
                  :key="index"
                  :element="element"
              />
            </div>
          </router-link>
        </div>
        <div class="footer mt-15">
          <v-btn @click="logout" color="error" elevation="2" outlined>
            Uitloggen
          </v-btn>
        </div>

        <v-dialog
            v-model="dialog.visible"
            persistent
            max-width="450px"
        >
          <v-card>
            <v-card-title calss="d-flex">
              <span class="text-h5">Profiel bijwerken</span>
            </v-card-title>

            <div v-if="dialog.loading" class="d-flex justify-center mt-5 mb-5">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
            <div v-else class="px-6 mt-4 ">
              <!--          <vue-form-generator ref="form" :schema="schema" :model="userModal" :options="formOptions"></vue-form-generator>-->
              <!--          <v-form-base :model="userModal" :schema="Schema" @input="editUser" />-->
              <v-text-field
                  v-model.trim="userModal.name"
                  :rules="[val => (val || '').length >= 5 || 'Minimaal 5 karakters']"
                  hint="Minimaal 5 karakters"
                  color="blue darken-2"
                  label="Naam"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="userModal.email"
                  color="blue darken-2"
                  label="Email"
                  disabled
              ></v-text-field>
              <v-text-field
                  v-model.trim="userModal.phoneNumber"
                  :rules="[val => (val || '').length > 0 || 'Dit veld is verplicht']"
                  hint="Dit veld is verplicht!"
                  color="blue darken-2"
                  label="Telefoon nummer"
                  :autofocus="phoneNumberExist"
                  prefix="+31"
                  required
              ></v-text-field>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog.visible = false"
                >Annuleren
                </v-btn>
                <v-btn
                    :disabled="!formIsValid"
                    text
                    color="blue darken-1"
                    type="submit"
                    @click="editUser"
                    :loading="dialog.loadingBtn"

                >
                  Opslaan
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {getAuth, updateProfile, updateEmail} from "firebase/auth";
import OrderElement from "@/components/OrderElement";

export default {
  name: "Profile",
  components: {
    OrderElement,
  },

  data() {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    this.$Auth.getAdres().then((address) => {
      this.address = address.formatted;
    });

    this.$Auth.getGroupedOrders().then((groupedOrders) => {
      groupedOrders.sort((a, b) => b.date._seconds - a.date._seconds);
      this.elements.push(...groupedOrders);
    });

    this.$Auth.getUser(currentUser.uid).then(user => {
      this.bigUser = Object.assign({}, user);;
      this.user = Object.assign({}, user.user);
      this.userModal = user.user
      this.userId = user.uid;
    }).catch(err => {
      this.$swal({
        title: "Error",
        icon: "error",
        text: "er is iets mis gegaan",
        confirmButtonText: "Yes",
        timer: 5000,
        timerProgressBar: true,
      }).then(() => {
        this.$router.push('/')
      })
    }).finally(() => {
      this.loading = false;
      if(!this.phoneNumberExist){
        this.$swal
            .fire({
              title: "Uw heeft geen telefoonnummer opgegeven",
              text: "Met uw telefoonnummer kunnen we u beter bereiken, het is geadviseerd om een telefoon nummer op te geven!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Telefoonnummer opgeven",
              cancelButtonText: "Annuleren",
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.dialog.visible = true;
              }
            });
      }
    })

    return {
      dialog: {
        visible: false,
        loadingBtn: false,
      },
      user: null,
      userModal: null,
      userId: null,
      loading: true,
      bigUser: null,
      signOut: this.$parent.signOut,
      address: null,
      form: {
        name: currentUser.displayName,
        email: currentUser.email,
      },
      elements: [],
      breadcrumbsItems: [
        {
          text: "Instellingen",
          href: "#/settings",
        },
        {
          text: "Profiel",
          disabled: true,
        },
      ],
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Naam',
            model: 'name',
          },
          {
            type: 'input',
            inputType: 'email',
            label: 'Email',
            model: 'email',
            disabled: true,
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Telefoon nummer',
            model: 'phoneNumber',
          },
        ],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  computed: {
    formIsValid() {
      return (
          this.userModal.name &&
          this.userModal.email &&
          this.userModal.phoneNumber
      )
    },
    phoneNumberExist() {
      return this.user?.phoneNumber ? this.user.phoneNumber.trim().length !== 0 : false
    },
  },
  methods: {
    logout() {
      this.$swal
          .fire({
            title: "Uitloggen?",
            text: "Weet u het zeker dat u wilt uitloggen!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ja, uitloggen",
            cancelButtonText: "Annuleren",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.signOut();
            }
          });
    },
    async editUser() {
      this.dialog.loadingBtn = true;
      const user = this.userModal;
      this.$Auth.updateUser(this.userId, user, this.bigUser).then(() => {
        this.user = Object.assign({}, user);
        this.$swal({
          title: "Success",
          icon: "success",
          text: "Profiel is bijgewerkt",
          timer: 5000,
          timerProgressBar: true,
        });
      }).catch(err => {
      }).finally(() => {
        this.dialog.visible = false;
        this.dialog.loadingBtn = false;
      })
    },
  },
};
</script>
