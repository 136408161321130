<template>
  <div class="routeContainer">
    <AuthFormComponent :data="data" />
  </div>
</template>

<script>
import AuthFormComponent from "@/components/AuthFormComponent";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default {
  name: "Login",
  components: {
    AuthFormComponent,
  },
  data() {
    return {
      signed: this.$parent.signed,
      data: {
        type: "login",
        callbackfn: this.submit,
        title: "Inloggen",
        contactBtns: [
          {
            text: "via Google",
            icon: "fa-brands fa-google",
            callbackFn: this.handleGoogleLogin,
          },
        ],
        footer: {
          text: "Heeft uw geen account, dan",
          link: {
            text: "registreer",
            path: "/register",
          },
        },
      },
    };
  },

  methods: {
    async submit(data) {
      if (!data.email || !data.password) {
        this.$swal({
          title: "Error",
          icon: "error",
          footer: "Issue #022",
          text: "er is iets mis gegaan",
          confirmButtonText: "Yes",
          timer: 5000,
          timerProgressBar: true,
        });
      }
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, data.email, data.password)
        .then(async(userCredential) => {
          const user = userCredential.user;
          await this.signed(user, userCredential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (
            this.$parent.firebaseCodeChecks(errorCode, errorMessage) ==
            "not handled"
          ) {
            this.$swal({
              title: "Error",
              icon: "error",
              footer: "Issue #022",
              text: "er is iets mis gegaan",
              confirmButtonText: "Yes",
              timer: 5000,
              timerProgressBar: true,
            });
          }
        });
    },
    async handleGoogleLogin() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      await signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;
          this.signed(user, credential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // const email = error.customData.email;
          // const credential = GoogleAuthProvider.credentialFromError(error);
          if (
            this.$parent.firebaseCodeChecks(errorCode, errorMessage) ==
            "not handled"
          ) {
            this.$swal({
              title: "Error",
              icon: "error",
              footer: "Issue G#022",
              text: "er is iets mis gegaan",
              confirmButtonText: "Yes",
              timer: 5000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
};
</script>
