<template>
  <div class="routeContainer">
    <AuthFormComponent :data="data" />
  </div>
</template>

<script>
import AuthFormComponent from "@/components/AuthFormComponent";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";

export default {
  name: "Register",
  components: {
    AuthFormComponent,
  },
  data() {
    return {
      signed: this.$parent.signed,
      data: {
        type: "register",
        callbackfn: this.submit,
        title: "Registratie",
        contactBtns: [
          {
            text: "via Google",
            icon: "fa-brands fa-google",
            callbackFn: this.handleGoogleRegiser,
          },
        ],
        footer: {
          text: "Heeft uw al een account, dan",
          link: {
            text: "login",
            path: "/login",
          },
        },
      },
    };
  },
  methods: {
    handleGoogleRegiser() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then(async(result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          const user = result.user;
          await this.signed(user, credential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // const credential = GoogleAuthProvider.credentialFromError(error);
          if (
            this.$parent.firebaseCodeChecks(errorCode, errorMessage) ===
            "not handled"
          ) {
            this.$swal({
              title: "Error",
              icon: "error",
              footer: "Issue G#022",
              text: "er is iets mis gegaan",
              confirmButtonText: "Yes",
              timer: 5000,
              timerProgressBar: true,
            });
          }
        });
    },
    async submit(data) {
      // console.log("form Register data: ", data);
      if (!data || data.password != data.againPassword) {
        this.$swal({
          title: "Error",
          icon: "error",
          footer: "Issue #011",
          text: "er is iets mis gegaan",
          confirmButtonText: "Yes",
          timer: 5000,
          timerProgressBar: true,
        });
      }

      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, data.email, data.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          await updateProfile(user, {
            displayName: data.name,
          });
          await this.signed(user, userCredential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (
            this.$parent.firebaseCodeChecks(errorCode, errorMessage) ==
            "not handled"
          ) {
            this.$swal({
              title: "Error",
              icon: "error",
              text: "er is iets mis gegaan",
              footer: "Issue #022",
              confirmButtonText: "Yes",
              timer: 5000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
};
</script>
